/*
 * index.js
 * author: evan kirkiles
 * created on Wed Feb 15 2023
 * 2023 channel studio
 */
import classNames from 'classnames';
import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useSelectLoadStatus, useSelectExplore } from 'slices/exploreSlice';
import { LoadState } from 'utils/constants';
import NavLink from 'components/Nav/NavLink';
import NavLoader from './NavLoader';

export default function Nav({ className }) {
  const loadStatus = useSelectLoadStatus();
  const { atlasLoaded, clusterLoaded } = useSelectExplore();

  return (
    <nav className={classNames('Nav', className)}>
      <SwitchTransition>
        <CSSTransition
          key={loadStatus === LoadState.Done ? 'contents' : 'loader'}
          timeout={300}
          classNames="Transition__fade"
        >
          {loadStatus === LoadState.Done ? (
            <ul className="flex space-between">
              <NavLink
                text="Map"
                path="/"
                tourState="map"
                disabled={!atlasLoaded}
              />
              <NavLink
                text="Clusters"
                path="/clusters"
                tourState="clusters"
                disabled={!clusterLoaded}
              />
              <NavLink
                text="Playlist"
                path="/playlists"
                tourState="playlists"
              />
            </ul>
          ) : (
            <NavLoader loadState={loadStatus} />
          )}
        </CSSTransition>
      </SwitchTransition>
    </nav>
  );
}
