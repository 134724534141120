import React from 'react';
import Header from 'components/Header';

export default function Privacy() {
  return (
    <>
      <Header className="Legal__header" />
      <section className="Legal">
        <h1>PRIVACY POLICY</h1>
        <p>Effective Date: November 1st, 2023.</p>
        <ol>
          <li>
            <h2>Introduction</h2>
            <p>
              This Privacy Policy describes how Kranzler Music (“Kranzler
              Music,” “we,” “our,” or “us”) collects, uses, and discloses
              information about you as well as your rights and choices regarding
              such information. For purposes of this Privacy Policy, unless
              otherwise stated, “information” or “personal information” means
              information relating to an identified or identifiable individual,
              and does not include aggregate information or information that
              does not identify you.
            </p>
            <p>
              This Privacy Policy applies to information we collect where we
              control the purposes and means of processing. Specifically, it
              applies to information we collect through any of our websites,
              emails, and other online services that link to this Privacy Policy
              (the “Service”). It does not apply to information collected by
              third parties or information collected in the context of your
              employment with us.
            </p>
            <p>
              Some regions provide additional rights by law. For region-specific
              terms, see the below applicable sections at the bottom of this
              Privacy Policy.
            </p>
            <p>
              Please note that your use of the Service is also subject to our{' '}
              <a href="/terms-of-use">Terms of Use</a>.
            </p>
            <p>
              For our contact details, see the{' '}
              <a href="#contact-us">Contact Us</a> section below.
            </p>
          </li>
          <li id="how-we-collect">
            <h2>How We Collect Information.</h2>
            <p>
              We collect information about you in a variety of contexts. Please
              see below for details.
            </p>
            <ol>
              <li>
                <h3>
                  Information You Voluntarily Provide through the Service.
                </h3>
                <p>
                  When you use the Service, you may be asked to provide
                  information to us, such as when you connect an account, update
                  your profile, or contact support. The categories of
                  information we collect include:
                </p>
                <ul>
                  <li>
                    <em>Contact Identifiers</em>, including your name and email
                    address.
                  </li>
                  <li>
                    <em>Characteristics or demographics</em>, including your
                    age, gender, and country.
                  </li>
                  <li>
                    <em>User-generated content</em>, including content within
                    any messages you send to us (such as feedback, questions, or
                    survey responses).
                  </li>
                </ul>
                <p>
                  Please do not provide any information that we do not request.
                </p>
              </li>
              <li>
                <h3>Information from Your Browser or Device</h3>
                <p>
                  When you use the Service, we and third parties we work with
                  automatically collect information from your browser or device.
                  The categories of information we automatically collect
                  include:
                </p>
                <ul>
                  <li>
                    <em>Device identifiers</em>, including your device’s IP
                    address.
                  </li>
                  <li>
                    <em>Device information</em>, including your device’s
                    operating software and browser (e.g., type, version, and
                    configuration), internet service provider, and regional and
                    language settings.
                  </li>
                  <li>
                    <em>Internet activity</em>, including information about your
                    browsing history and interactions, such as the features you
                    use, pages you visit, content you view, time of day you
                    browse, and referring and exiting pages.
                  </li>
                </ul>
                <p>
                  This information is automatically collected through cookies
                  and other tracking technologies incorporated into our Service,
                  as described below:
                </p>
                <ul>
                  <li>
                    <em>Cookies.</em> Cookies are small text files which are
                    placed on your browser when you visit a website, open or
                    click on an email, or interact with an advertisement. Our
                    Service uses session cookies (which expire when you close
                    your browser) and persistent cookies (which expire at a set
                    expiration date or when you manually delete them). We
                    incorporate both first party cookies (which are cookies
                    served directly by us) and third party cookies (which are
                    cookies served by third parties we work with). We use
                    cookies for a variety of purposes, including to help make
                    our website work, personalize your browsing experience,
                    prevent fraud and assist with security, perform measurement
                    and analytics, and provide advertising.
                  </li>
                  <li>
                    <em>Pixels.</em> Pixels (also known as web beacons) are code
                    embedded within a service. There are various types of
                    pixels, including image pixels (which are one-pixel
                    transparent images) and JavaScript pixels (which contain
                    JavaScript code). Pixels are often associated with cookies
                    and are used for similar purposes. When you access a service
                    that contains a pixel, the pixel may permit us or a third
                    party to drop or read cookies on your browser, or collect
                    other information about your browser or device.
                  </li>
                  <li>
                    <em>Google Analytics.</em> We use Google Analytics on the
                    Service to collect the information described above. Google
                    Analytics uses cookies and pixels to automatically collect
                    certain information from you when you access the Service. To
                    learn more about how Google uses the information collected,
                    please visit the webpage{' '}
                    <em>
                      “How Google uses information from sites or apps that use
                      our services”
                    </em>
                    , located at{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="www.google.com/policies/privacy/partners/"
                    >
                      www.google.com/policies/privacy/partners/
                    </a>
                    .
                  </li>
                </ul>
                <p>
                  For details on your rights and choices around cookies and
                  other tracking technologies, see the{' '}
                  <a href="#rights-and-choices">
                    Your Rights and Choices section below.
                  </a>
                </p>
              </li>
              <li>
                <h3>Information from Other Sources</h3>
                <p>
                  We also collect information from other sources. The categories
                  of sources from which we collect information include:
                </p>
                <ul>
                  <li>
                    <em>Business partners</em> that offer co-branded services,
                    sell or distribute our products, or engage in joint
                    marketing or promotional activities.
                  </li>

                  <li>
                    <em>Third party vendors and related parties</em> we work
                    with in connection with receiving analytics, advertising,
                    security, and fraud prevention services.
                  </li>

                  <li>
                    <em>Social media platforms</em> with which you interact. For
                    example, when you “like,” “follow”, or otherwise engage with
                    our content on social media (such as through our brand page
                    or direct message), we may collect information such as your
                    contact identifiers and any comments you provide. If you
                    publicly reference our Service on social media (such as by
                    using a hashtag associated with us in a tweet or post), we
                    may use your reference on or in connection with our Service.
                  </li>

                  <li>
                    <em>Connected music streaming platforms</em>. If you choose
                    to log-in to the Service using your music streaming platform
                    credentials, such as through Spotify or Apple Music log-in,
                    we may receive additional information from that music
                    streaming platform that you have authorized it to disclose
                    to us, such as your username, your email address, your music
                    preferences (including, but not limited to, liked song,
                    liked artists, and liked albums), and playlists.
                  </li>

                  <li>
                    <em>Public sources</em>, including where information is in
                    the public domain.
                  </li>
                </ul>
              </li>
              <li>
                <h3>Sensitive Information.</h3>
                <p>
                  To the extent any of categories of information we collect are
                  sensitive categories of information under applicable law, we
                  process such information only for the limited purposes
                  permitted by applicable law. We do not sell or use sensitive
                  categories of information for purposes of targeted advertising
                  or to make inferences.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>How We Use Information.</h2>
            <p>
              We collect and use information in accordance with the practices
              described in this Privacy Policy. Our purposes for collecting and
              using information include:
            </p>
            <ul>
              <li>
                <em>Providing services</em>. We use information to provide
                services to you, including to operate the Service, establish and
                maintain your account, and provide support.
              </li>
              <li>
                <em>Personalizing your experience</em>. We use information to
                personalize your experience and show you content we believe you
                will find interesting.
              </li>
              <li>
                <em>Research and development</em>. We use information for
                research and development purposes, including to improve our
                services and make business and marketing decisions.
              </li>
              <li>
                <em>Communications</em>. We use information to communicate with
                you about updates, security alerts, changes to policies, and
                other transactional messages. We also use information to
                personalize and deliver marketing communications to you.
                Communications may be by email
              </li>
              <li>
                <em>Analytics</em>. We use information to understand trends,
                usage, and activities, for example through surveys you respond
                to and tracking technologies that we incorporate into the
                Service (such as Google Analytics).
              </li>
              <li>
                <em>Advertising</em>. We work with agencies, ad networks,
                technology providers, and other third parties to place ads about
                our products and services on other websites and services. For
                example, we place ads through Google and Facebook that you may
                view on their platforms as well as on other websites and
                services. As part of this process, we incorporate tracking
                technologies into our own Service as well as into our ads
                displayed on other websites and services. Some of these tracking
                technologies may track your activities over time and across
                non-affiliated services and obtain or infer information about
                you for purposes of showing you relevant advertising based on
                your preferences and interests (“targeted advertising”).
              </li>
              <li>
                <em>Security and enforcement</em>. We use information to
                prevent, detect, investigate, and address fraud, breach of
                policies or terms, or threats or harm.
              </li>
              <li>
                <em>At your direction or with your consent</em>. We use
                information for additional purposes where you direct us to use
                it in a certain way or with notice to you and your consent.
              </li>
            </ul>
            <p>
              Sometimes we aggregate or de-identify information so it is no
              longer considered personal information. We may use non-personal
              information for any purpose to the extent permitted by applicable
              law. For details on your rights and choices around use of your
              information, see the{' '}
              <a href="#rights-and-choices">
                Your Rights and Choices section below.
              </a>
            </p>
          </li>
          <li>
            <h2>How We Disclose Information.</h2>
            <p>
              We disclose the information we collect in accordance with the
              practices described in this Privacy Policy. The categories of
              persons to whom we disclose information include:
            </p>
            <ul>
              <li>
                <em>Service providers</em>. Many of the third parties we work
                with are service providers that collect and process information
                on our behalf in accordance with this Privacy Policy. Service
                providers perform services for us such as payment processing,
                data analytics, marketing and advertising, website hosting, and
                technical support. To the extent required by law, we
                contractually prohibit our service providers from processing
                information they collect on our behalf for purposes other than
                performing services for us, although we may permit them to use
                non-personal information for any purpose to the extent permitted
                by applicable law
              </li>
              <li>
                <em>Third party vendors and related parties</em>. Some of the
                third parties we work with to perform services act as our
                service providers in some contexts, but in other contexts
                independently control the purposes and means of processing your
                information. For example, we disclose information to ad
                networks, technology providers, and other third parties that
                help provide targeted advertising, but may also use information
                for their own purposes. For these third parties, we encourage
                you to familiarize yourself with and consult their policies and
                terms of use.
              </li>
              <li>
                <em>Recipients in a merger or acquisition</em>. We disclose
                information in connection with, or during negotiations of, any
                proposed or actual merger, purchase, sale or any other type of
                acquisition or business combination of all or any portion of our
                assets, or transfer of all or a portion of our business to
                another business.
              </li>
              <li>
                <em>Recipients for security and enforcement</em>. We disclose
                information to comply with the law or other legal process, and
                where required, in response to lawful requests by public
                authorities, including to meet national security or law
                enforcement requirements. We also disclose information to
                protect the rights, property, life, health, security and safety
                of us, the Service or anyone else.
              </li>
              <li>
                <em>Recipients at your direction or with your consent</em>. We
                disclose information where you direct us to or with notice to
                you and your consent.
              </li>
            </ul>
            <p>
              Sometimes we aggregate or de-identify information so it is no
              longer considered personal information. We may disclose
              non-personal information for any purpose to the extent permitted
              by applicable law. For details on your rights and choices around
              disclosure of your information, see the{' '}
              <a href="#rights-and-choices">
                Your Rights and Choices section below.
              </a>
            </p>
          </li>
          <li>
            <h2>Third Parties</h2>
            <p>
              To access our Service, you must link your account from applicable
              music streaming platforms, offered and controlled by third
              parties. In addition, we may integrate technologies, including
              those disclosed in the{' '}
              <a href="#how-we-collect">How We Collect Information</a> section
              above, controlled by third parties. Except where third parties act
              as our service providers, they, and not us, control the purposes
              and means of processing any information they collect from you, and
              you should contact them directly to address any concerns you have
              about their processing. Third party data practices are subject to
              their own policies and disclosures, including what information
              they collect, your rights and choices, and whether they store
              information in the U.S. or elsewhere. We encourage you to
              familiarize yourself with and consult their privacy policies and
              terms of use.
            </p>
            <p>
              Some examples of where you may interact with a third party
              include:
            </p>
            <ul>
              <li>
                <em>Links</em>. Our Service includes hyperlinks to third party
                websites and services.
              </li>
              <li>
                <em>Account linking</em>. To use the Service, you must link your
                account to third party music streaming platforms in order to
                retrieve certain data about your use of that service. For
                example, if you link your account to your music streaming
                platform account, such as Spotify or Apple Music, the linking
                will allow us to obtain information such as your username, email
                address, photo, music preferences, and playlists. Please note
                that the third party may independently collect information about
                you through the integration
              </li>
            </ul>
          </li>
          <li id="rights-and-choices">
            <h2>Your Rights and Choices.</h2>
            <p>
              We provide a variety of ways for you to control the information we
              process about you. Please see below for details.
            </p>
            <ul>
              <li>
                <h3>Communications.</h3>
                <ul>
                  <li>
                    <em>Emails</em>. You can unsubscribe from receiving
                    promotional emails by following the unsubscribe instructions
                    near the bottom of such emails, or by emailing us at as set
                    out in the <a href="#contact-us">Contact Us</a> section
                    below with the word “UNSUBSCRIBE” in the subject field of
                    the email. Please note that you cannot opt out of
                    transactional messages.
                  </li>
                  <li>
                    <em>Push notifications</em>. If you have opted-in to receive
                    browser push notification on your device, you can opt-out by
                    adjusting your browser or device settings.
                  </li>
                </ul>
                <p>
                  Please note that your opt out is limited to the email address
                  or browser used and will not affect subsequent subscriptions.
                </p>
              </li>
              <li>
                <h3>Accounts.</h3>
                <p>
                  If you hold an account with us, you can delete your account
                  through your account settings. We will address your request in
                  accordance with our data retention practices.
                </p>
              </li>
              <li>
                <h3>Browser and device controls.</h3>
                <ul>
                  <li>
                    <em>Cookies and Pixels</em>. You may be able to manage
                    cookies through your browser settings. When you manage
                    cookies, pixels associated with such cookies may also be
                    impacted. Please note that cookie management only applies to
                    our website. If you use multiple browsers, you will need to
                    instruct each browser separately. If you delete or reset
                    your cookies, you will need to reconfigure your settings.
                    Your ability to limit cookies is subject to your browser
                    settings and limitations
                  </li>
                  <li>
                    <em>Preference signals</em>. Your browser or extension may
                    allow you to automatically transmit Do Not Track and other
                    preference. We do not respond to such signals except where
                    required by law.
                  </li>
                  <li>
                    <em>Third party opt-out tools</em>. Some third parties we
                    work with offer their own opt-out tools related to
                    information collected through cookies and pixels. To opt out
                    of your information being used by Google Analytics, please
                    visit https://tools.google.com/dlpage/gaoptout. We are not
                    responsible for the effectiveness of any third party opt-out
                    tools
                  </li>
                  <li>
                    <em>Industry opt-out tools for targeted advertising</em>.
                    Some of the third parties we work with participate in
                    programs that allow you to opt-out of receiving targeted
                    advertising from participants. To opt-out of receiving
                    targeted advertising from participants of the Digital
                    Advertising Alliance (“DAA”) on your browser, visit
                    https://www.aboutads.info/choices. To opt-out of receiving
                    targeted advertising from participants of the Network
                    Advertising Initiative (“NAI”) on your browser, visit
                    https://www.networkadvertising.org/choices/. If you choose
                    to opt-out of targeted advertising through these links, you
                    should no longer see targeted advertising from the selected
                    participants on the browser or device from which you
                    opted-out, but the opt-out does not mean that the
                    participants will not process your information for targeted
                    advertising purposes or that you will not receive any
                    advertising. We are not responsible for the effectiveness of
                    any third party opt-out tools
                  </li>
                </ul>
              </li>
              <li>
                <h3>Linked Accounts</h3>
                <p>
                  If you have linked your music streaming account with our
                  Service, you may unlink your accounts at any time by visiting
                  your Kranzler Music or third party music streaming account
                  settings. Please note that unlinking your accounts will not
                  affect any information previously disclosed through the
                  linking. We are not responsible for the data practices of any
                  third parties, and we recommend that you carefully review
                  their privacy policies and terms of use.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <h2>Children</h2>
            <p>
              The Service is not directed toward children under 13 years old,
              and we do not knowingly collect personal information (as that term
              is defined by the U.S. Children’s Privacy Protection Act, or
              “COPPA”) from children. If you are a parent or guardian and
              believe we have collected personal information from children,
              please contact us as set out in the{' '}
              <a href="#contact-us">Contact Us</a> section below. We will delete
              the personal information in accordance with COPPA.
            </p>
          </li>
          <li>
            <h2>Retention.</h2>
            <p>
              We retain information for the length of time that is reasonably
              necessary for the purpose for which it was collected, and as
              necessary to comply with our legal obligations, resolve disputes,
              prevent fraud, and enforce our agreements.
            </p>
          </li>
          <li>
            <h2>International Transfer.</h2>
            <p>
              We are based in the U.S. and the information we collect is
              governed by U.S. law. If you are accessing the Service from
              outside the U.S., please be aware that information collected
              through the Service may be transferred across borders, and from
              your country or jurisdiction to other countries or jurisdictions
              around the world, including the U.S. Data protection laws in the
              U.S. and other jurisdictions may be different from those of your
              country of residence. By using the Service, you are expressly
              consenting to the transfer to and from, processing, usage,
              sharing, and storage of your information, including personal data,
              in the U.S. as well as other jurisdictions where we conduct
              business or provide services. If your data is collected in the
              United Kingdom, the European Economic Area or Switzerland
              (collectively, “Europe”), we will transfer your personal data
              subject to appropriate safeguards, such as Standard Contractual
              Clauses.
            </p>
          </li>
          <li>
            <h2>Changes to this Privacy Policy.</h2>
            <p>
              We reserve the right to revise and reissue this Privacy Policy at
              any time. Any changes will be effective immediately upon posting
              of the revised Privacy Policy. Your continued use of our Service
              indicates your consent to the Privacy Policy then posted. If the
              changes are material, we may provide additional notice to you,
              such as through email or prominent notice on the Service.
            </p>
          </li>
          <li id="#contact-us">
            <h2>Contact Us.</h2>
            <p>
              If you have any questions about or trouble accessing this Privacy
              Policy, please contact us:
            </p>
            <ul>
              <li>By email: cm@kranzler.net</li>
              <li>By mail: PO Box 67, Arkville, NY 12406</li>
            </ul>
          </li>
          <li>
            <h2>California Shine the Light.</h2>
            <p>
              Under California’s Shine the Light law, customers who are
              residents of California may request (i) a list of the categories
              of personal information disclosed by us to third parties during
              the immediately preceding calendar year for those third parties’
              own direct marketing purposes; and (ii) a list of the categories
              of third parties to whom we disclosed such information. To
              exercise a request and to the extent we are required to honor your
              request under applicable law, please write us at the email or
              postal address set out in the <a href="#contact-us">Contact Us</a>{' '}
              section above and specify that you are making a “California Shine
              the Light Request.” We may require additional information from you
              to allow us to verify your identity and are only required to
              respond to requests once during any calendar year (if at all).
            </p>
          </li>
          <li>
            <h2>Nevada.</h2>
            <p>
              If you are a Nevada consumer, you have the right to direct us not
              to sell certain information that we have collected or will collect
              about you. To the extent that we sell any information about you
              and as required by applicable law, you may exercise this right by
              emailing us at cm@kranzler.net and specify you wish to exercise
              this right.
            </p>
          </li>
        </ol>
      </section>
    </>
  );
}
