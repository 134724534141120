/*
 * RequireExplore.js
 * author: evan kirkiles
 * created on Mon Feb 06 2023
 * 2023 channel studio
 *
 * Ensures that the grid, graph, and clusters are loaded before displaying its
 * children. Should only come inside of the RequireLibrary component, as
 * library is requirerd before any explore processing is done.
 */
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useSelectLoadStatus } from 'slices/exploreSlice';
import { LoadState } from 'utils/constants';
import LoadingExplore from 'views/Loading/LoadingExplore';

export default function RequireExplore({ children, noLoader }) {
  const loadStatus = useSelectLoadStatus();
  const exploreLoaded = loadStatus === LoadState.Done;
  if (noLoader && !exploreLoaded) return null;

  // if explore not yet crawled, return the explore loading viiew
  return (
    <TransitionGroup component={null}>
      <CSSTransition
        key={exploreLoaded ? 'contents' : 'loader'}
        classNames="Transition__fade"
        timeout={200}
      >
        {exploreLoaded ? children : <LoadingExplore />}
      </CSSTransition>
    </TransitionGroup>
  );
}
