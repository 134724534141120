/*
 * LoadingExplore.js
 * author: evan kirkiles
 * created on Mon Feb 06 2023
 * 2023 channel studio
 */
import classNames from 'classnames';
import ImageCycler from 'components/ImageCycler';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useSelectExplore, useSelectLoadStatus } from 'slices/exploreSlice';
import { LoadMessages, LoadState } from 'utils/constants';

export default function LoadingPage() {
  const { progress, total } = useSelectExplore();
  const loadStatus = useSelectLoadStatus();
  if (total < 1) {
    return null;
  }

  const progressPercent =
    loadStatus === LoadState.ExtractingTracks ? progress / total : 0.35;

  return (
    <article className="Loading">
      <section className="Loading__inner">
        <div className="Loading__pane Loading__pane-bordered">
          <div className="Loading__explorecycler">
            <ImageCycler />
            <svg className="Loading__exploreprogress" viewBox="0 0 44 44">
              <circle
                stroke="#333333"
                strokeWidth="0.5"
                fill="transparent"
                r="18"
                cx="22"
                cy="22"
              />
              <circle
                className={classNames('Nav__loaderprogress-wheel', {
                  'Nav__loaderprogress-wheel-infinite':
                    loadStatus !== LoadState.ExtractingTracks
                })}
                stroke="white"
                strokeWidth="1"
                fill="transparent"
                r="18"
                cx="22"
                cy="22"
                style={{
                  strokeDashoffset: `${(1 - progressPercent) * 2 * 18 * 3.14}`
                }}
              />
            </svg>
            <SwitchTransition>
              <CSSTransition
                key={loadStatus}
                classNames="Transition__fade"
                appear={true}
                timeout={300}
              >
                <div className="Loading__status">
                  <div>{LoadMessages[loadStatus][0]}</div>
                  {loadStatus === LoadState.ExtractingTracks && (
                    <div className="Loading__status-info">
                      {Math.floor((1000 * progress) / total) / 10}% complete
                      <br />
                      {progress} of {total} tracks
                    </div>
                  )}
                </div>
              </CSSTransition>
            </SwitchTransition>
          </div>
          <div className="Loading__disclaimer">
            <span>
              This may take several minutes, and even longer for larger
              libraries.
            </span>
            <span>
              Closing this tab will not interrupt processing, so feel free to
              come back later.
            </span>
          </div>
        </div>
      </section>
    </article>
  );
}
