/*
 * tourSlice.js
 * author: evan kirkiles
 * created on Sun Apr 02 2023
 * 2023 channel studio
 */
import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  mapToured: false,
  clustersToured: false,
  playlistsToured: false,
  menuTipToured: false,
  libraryTipToured: false
};

export const tourSlice = createSlice({
  name: 'tour',
  initialState,
  reducers: {
    clearTour: () => initialState,
    setToured: (state, action) => {
      const { item, toured } = action.payload;
      if (state[`${item}Toured`] !== undefined) {
        state[`${item}Toured`] = toured;
      } else {
        console.error(`Invalid tour item in state: ${item}Toured`);
      }
    }
  }
});

export const { clearTour, setToured } = tourSlice.actions;

export default tourSlice.reducer;

export const useSelectToured = (item) =>
  useSelector((state) => state.tour[`${item}Toured`]);
