/*
 * Login.tsx
 * author: evan kirkiles
 * created on Sat Jan 14 2023
 * 2023 channel studio
 *
 * Login page––redirects you off once logged in.
 */
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import AppleMusicLoginFlow from 'pages/Login/AppleMusicFlow';
import SpotifyLoginFlow from 'pages/Login/SpotifyFlow';
import { useEffect } from 'react';
import {
  clearPlatform,
  setUserInfo,
  useSelectLoggedIn,
  useSelectPlatform
} from 'slices/userSlice';
import IconLogo from 'svg/logo2';

export default function LoginPage() {
  const platform = useSelectPlatform();
  const loggedIn = useSelectLoggedIn();
  const dispatch = useDispatch();

  // routing to restore previous URL
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const user_id = searchParams.get('user_id');
  const display_name = searchParams.get('display_name');
  const frontend_token = searchParams.get('frontend_token');
  const backend_platform = searchParams.get('backend_platform');
  const needs_login = searchParams.get('needs_login');
  const error = searchParams.get('error');

  useEffect(() => {
    if (loggedIn) {
      navigate('/');
    }
  }, [loggedIn, navigate]);

  useEffect(() => {
    dispatch(
      setUserInfo({
        user_id,
        display_name,
        frontend_token,
        backend_platform,
        needs_login
      })
    );
  }, [
    dispatch,
    user_id,
    display_name,
    frontend_token,
    backend_platform,
    needs_login,
    setSearchParams
  ]);

  useEffect(() => {
    if (error) {
      console.error(error);
      dispatch(clearPlatform());
      setSearchParams({});
    }
  }, [error, dispatch, setSearchParams]);

  if (loggedIn) return null;

  return (
    <div className="Login">
      <IconLogo className="Login__logo-icon" />
      <div className="Login__inner">
        <p className="Login__description">
          Kranzler Music is an experimental music browser enabled by machine
          learning.{'\n'}
          Log in with your Spotify or Apple Music account to visualize your
          library.
        </p>
        <div className="Login__login-methods">
          <SpotifyLoginFlow platform={platform} />
          <AppleMusicLoginFlow platform={platform} />
        </div>
        <div className="Login__beta-label">Beta</div>
      </div>
      <div className="Login__footer">
        <Link to="/about">About</Link>
        <ul className="Login__legal">
          <li>
            <Link to="/terms-of-use" target="_blank" rel="noopener noreferrer">
              Terms of Use
            </Link>
            <span>{' | '}</span>
          </li>
          <li>
            <Link
              to="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
