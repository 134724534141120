import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchData, startProcess } from 'api/client';
import { useSelector } from 'react-redux';
import { ErrorState, LoadState } from 'utils/constants';
import { runFetch } from 'utils/sliceUtils';

/* -------------------------------------------------------------------------- */
/*                                   Thunks                                   */
/* -------------------------------------------------------------------------- */

export const startProcessLibrary = createAsyncThunk(
  'explore/startProcess',
  async (_, { dispatch, getState }) => {
    const {
      user: { ID, token }
    } = getState();
    await runFetch(dispatch, startProcess, { ID, token });
  }
);

export const handleData = createAsyncThunk(
  'explore/handleData',
  async ({ dataType }, { getState, requestId, dispatch }) => {
    const { user, explore } = getState();
    const { ID, token } = user;
    if (
      explore[`${dataType}Loaded`] ||
      explore[`${dataType}RequestId`] !== requestId
    ) {
      return null;
    }
    return await runFetch(dispatch, fetchData, { file: dataType, ID, token });
  },
  {
    condition: ({ dataType, data }, { getState }) => {
      if (!data) {
        return false;
      }
      const { explore } = getState();
      if (explore[`${dataType}Loaded`] || explore[`${dataType}RequestId`]) {
        return false;
      }
    }
  }
);

/* -------------------------------------------------------------------------- */
/*                                    Slice                                   */
/* -------------------------------------------------------------------------- */

const setGrid = (data) => handleData({ dataType: 'grid', data: data });
const setCluster = (data) => handleData({ dataType: 'cluster', data: data });
const setAtlas = (data) => handleData({ dataType: 'atlas', data: data });

const initialState = {
  progress: -1,
  total: -1,
  preprocessLoaded: false,
  graphLoaded: false,
  gridLoaded: false,
  gridRequestId: null,
  clusterLoaded: false,
  clusterRequestId: null,
  atlasLoaded: false,
  atlasRequestId: null,
  errored: false,
  grid: null,
  cluster: null,
  atlas: null
};

export const exploreSlice = createSlice({
  name: 'explore',
  initialState,
  reducers: {
    clearExplore: () => initialState,
    startCheckProgress: () => {},
    stopCheckProgress: () => {},
    setProgress: (state, action) => {
      // set extraction progress
      state.progress = action.payload;
    },
    setTotal: (state, action) => {
      // set the total to be extracted
      state.total = action.payload;
    },
    setGraph: (state, action) => {
      state.graphLoaded ||= action.payload;
    },
    setPreprocess: (state, action) => {
      state.preprocessLoaded = action.payload;
    },
    setErrored: (state, action) => {
      state.errored ||= action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(handleData.fulfilled, (state, { meta, payload }) => {
      const { dataType } = meta.arg;
      if (state[`${dataType}Loaded`]) {
        return;
      }
      state[`${dataType}RequestId`] = null;
      if (!payload) {
        return;
      }
      state[dataType] = payload;
      state[`${dataType}Loaded`] = true;
    });
    builder.addCase(handleData.pending, (state, { meta }) => {
      const { arg, requestId } = meta;
      const { dataType, data } = arg;
      if (data) {
        state[`${dataType}RequestId`] = requestId;
      }
    });
    builder.addCase(handleData.rejected, (state, { arg, meta }) => {
      if (state[`${arg?.dataType}RequestId`] === meta.requestId) {
        state[`${arg.dataType}RequestId`] = null;
      }
    });
  }
});

export const {
  startCheckProgress,
  stopCheckProgress,
  setProgress,
  setTotal,
  setGraph,
  setPreprocess,
  clearExplore,
  setErrored
} = exploreSlice.actions;
export { setGrid, setCluster, setAtlas };

export default exploreSlice.reducer;

export const useSelectExplore = () => useSelector((state) => state.explore);
export const useSelectExploreTotal = () =>
  useSelector((state) => state.explore.total);
export const useSelectExploreCluster = () =>
  useSelector((state) => state.explore.cluster);
export const useSelectGridAtlas = () =>
  useSelector(({ explore: { grid, atlas } }) => ({ grid, atlas }));
export const useSelectExploreGrid = () =>
  useSelector(({ explore: { grid } }) => grid);

/**
 * Returns the load state and any loader information
 * @param {*} state
 * @returns
 */
export const useSelectLoadStatus = () =>
  useSelector((state) => {
    const { libraryCrawled, libraryLoaded } = state.library;
    const {
      graphLoaded,
      gridLoaded,
      clusterLoaded,
      preprocessLoaded,
      progress,
      total,
      atlasLoaded
    } = state.explore;
    if (total === -1) return LoadState.LoadingPersistor;
    if (!libraryCrawled) return LoadState.LibraryCrawling;
    if (!libraryLoaded) return LoadState.LibraryLocalLoading;
    if (progress !== total) return LoadState.ExtractingTracks;
    if (!preprocessLoaded && !clusterLoaded) return LoadState.Preprocessing;
    if (!graphLoaded) return LoadState.LoadGraph;
    if (!gridLoaded) return LoadState.LoadGrid;
    if (!clusterLoaded) return LoadState.LoadClusters;
    if (!atlasLoaded) return LoadState.LoadAtlas;
    return LoadState.Done;
  });

export const useSelectErrorStatus = () =>
  useSelector(
    ({
      library: { libraryCrawled },
      explore: {
        errored,
        graphLoaded,
        gridLoaded,
        gridRequestId,
        clusterLoaded,
        clusterRequestId,
        preprocessLoaded,
        progress,
        total,
        atlasLoaded
      }
    }) => {
      if (!errored) {
        return [ErrorState.NoError];
      } else if (!libraryCrawled) {
        return [ErrorState.LibraryError];
      } else if (!atlasLoaded) {
        return [ErrorState.AtlasError];
      } else if (progress < total) {
        return [ErrorState.ExtractionError];
      } else if (!preprocessLoaded) {
        return [ErrorState.PreprocessingError];
      }
      const errorMessages = [];
      if (!graphLoaded) {
        errorMessages.push(ErrorState.GraphError);
      }
      if (!gridRequestId && !gridLoaded) {
        errorMessages.push(ErrorState.GridError);
      }
      if (!clusterRequestId && !clusterLoaded) {
        errorMessages.push(ErrorState.ClusterError);
      }
      return errorMessages;
    }
  );
