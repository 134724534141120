import { useDispatch } from 'react-redux';

function AppleMusicPopup({ appleLogin, appleDevToken, setPopupVisible }) {
  const dispatch = useDispatch();
  return (
    <>
      <div className="Login__backgroundcover" />
      <div className="Login__applepopup">
        <p>
          {appleDevToken
            ? 'Success! We’re almost there, now we just need you to login with your Apple Music account.'
            : 'To process your library we will need you to log in first with your Apple ID, and then with Apple Music.'}
        </p>
        <div className="Login__applepopupbuttons">
          <div>{appleDevToken ? 2 : 1}/2</div>
          <button
            onClick={() => {
              setPopupVisible(false);
              dispatch({ type: 'CLEAR_ALL' });
            }}
          >
            Cancel
          </button>
          <button onClick={appleLogin}>Log in</button>
        </div>
      </div>
    </>
  );
}

export default AppleMusicPopup;
