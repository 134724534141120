/*
 * index.js
 * author: evan kirkiles
 * created on Wed Feb 01 2023
 * 2023 channel studio
 */

import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AboutPane from './AboutPane';
import ProfilePane from './ProfilePane';
import { useSelectDisplayName } from 'slices/userSlice';

export default function Menu({ className, expanded, closeMenu }) {
  const location = useLocation();
  const displayName = useSelectDisplayName();

  // "profile" or "about"
  const [openView, setOpenView] = useState(null);
  const deactivate = () => setOpenView(null);
  useEffect(() => {
    if (expanded) {
      setOpenView(null);
    }
  }, [expanded]);

  return (
    <nav
      className={classNames('Menu', className, { 'Menu-expanded': expanded })}
    >
      <div className="Menu__wrapper">
        <ul
          className={classNames('Menu__items', '', {
            [`Menu__items-${openView}-view`]: openView,
            'Menu__items-inactive': openView
          })}
        >
          <li
            className={classNames({
              current: location.pathname.startsWith('/')
            })}
          >
            <Link onClick={closeMenu} to="/">
              Home
            </Link>
          </li>
          {!location.pathname.startsWith('/about') && (
            <li className="Menu__li_about">
              <button onClick={() => setOpenView('about')}>About</button>
            </li>
          )}
          {displayName && (
            <>
              <li className="Menu__li_profile">
                <button onClick={() => setOpenView('profile')}>Profile</button>
              </li>
              <li>
                <Link onClick={closeMenu} to="/logout">
                  Logout
                </Link>
              </li>
            </>
          )}
          <li className="Menu__legal">
            <Link
              onClick={closeMenu}
              to="/terms-of-use"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </Link>
            <span>{' | '}</span>
            <Link
              onClick={closeMenu}
              to="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>
          </li>
        </ul>
        <ProfilePane
          active={openView === 'profile'}
          deactivate={deactivate}
          closeMenu={closeMenu}
        />
        <AboutPane active={openView === 'about'} deactivate={deactivate} />
      </div>
      <div className="Menu__credit">
        Made by{' '}
        <a
          className="Menu__studiolink"
          href="mailto:sethkranzler@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Seth Kranzler.
        </a>
      </div>
    </nav>
  );
}
