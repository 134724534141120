/*
 * AppleMusicFlow.js
 * author: evan kirkiles
 * created on Wed Feb 01 2023
 * 2023 channel studio
 */
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { TfiClose } from 'react-icons/tfi';
import { useDispatch } from 'react-redux';
import { fetchAppleToken, useSelectAppleDevToken } from 'slices/uiSlice';
import { clearPlatform, submitAppleMusicToken } from 'slices/userSlice';
import AppleMusicPopup from './AppleMusicPopup';

export default function AppleMusicLoginFlow({ platform }) {
  const active = platform === 'apple';
  const hidden = !active && !!platform;

  // statefuls
  const dispatch = useDispatch();
  const origin = encodeURI(window.location.origin);
  const [stateMessage, setStateMessage] = useState(null);
  const appleDevToken = useSelectAppleDevToken();
  const [showPopup, setPopupVisible] = useState(false);

  // 0. on activation, describe action
  useEffect(() => {
    setStateMessage(active ? 'Authenticating with Apple Music...' : null);
  }, [active]);

  useEffect(() => {
    window.AppleID.auth.init({
      clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
      scope: 'email name',
      redirectURI: `${process.env.REACT_APP_BACKEND_URL}authorization/login_apple`,
      state: `${origin}/login`,
      usePopup: false
    });
  }, [origin]);

  const appleLogin = useCallback(() => {
    if (appleDevToken) {
      try {
        window.MusicKit.getInstance()
          .authorize()
          .then((appleToken) => {
            setStateMessage('Authenticated with Apple Music.');
            dispatch(submitAppleMusicToken({ appleToken }));
          });
      } catch (e) {
        console.error(e);
        setStateMessage(null);
        dispatch({ type: 'CLEAR_ALL' });
      }
      return;
    }
    window.AppleID.auth.signIn();
  }, [dispatch, appleDevToken]);

  // get the apple token on mount
  useEffect(() => {
    if (active) {
      dispatch(fetchAppleToken());
    }
  }, [active, dispatch]);

  // 1. use musickit to authorize once apple dev token has loaded
  useEffect(() => {
    if (!appleDevToken) {
      return;
    }
    window.MusicKit.configure({
      developerToken: appleDevToken,
      app: {
        name: 'channel music',
        build: '1.0.0'
      }
    });
    setPopupVisible(true);
  }, [appleDevToken]);

  return (
    <div
      className={classNames('Login__login-flow', {
        'Login__login-flow--hidden': hidden
      })}
    >
      {showPopup && (
        <AppleMusicPopup
          appleLogin={appleLogin}
          appleDevToken={appleDevToken}
          setPopupVisible={setPopupVisible}
        />
      )}
      <button
        onClick={() => {
          if (platform !== 'apple') {
            setPopupVisible(true);
          } else {
            dispatch(clearPlatform());
            setStateMessage(null);
          }
        }}
        className={classNames('Button', `Login__login-button`, {
          'Login__login-button--active': active
        })}
      >
        {platform === 'apple' ? (
          <>
            Logging in with Apple Music
            <span>
              <TfiClose />
            </span>
          </>
        ) : (
          <>
            Log in with Apple Music<span>→</span>
          </>
        )}
      </button>
      {stateMessage && (
        <div className="Login__statemessage medium-text">
          <span>{stateMessage}</span>
        </div>
      )}
    </div>
  );
}
