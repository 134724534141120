import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchAlbumData,
  fetchArtistData,
  fetchBookmarks,
  fetchData,
  fetchPlaylistData,
  fetchTrackData
} from 'api/client';

export const runFetch = async (dispatch, fetchFunc, args) => {
  return (await dispatch(fetchHandler({ fetchFunc, args }))).payload;
};

const fetchHandler = createAsyncThunk(
  'fetchHandler',
  async ({ fetchFunc, args }, { dispatch }) => {
    const response = await fetchFunc(args);
    if (response.unauthorized) {
      dispatch({ type: 'CLEAR_ALL' });
      return null;
    }
    return response;
  }
);

function* batchData(data) {
  for (let i = 0; i < Math.floor(1 + data.length / 75); i++) {
    yield data.slice(i * 75, (i + 1) * 75);
  }
}

export const getLibraryWithTracks = async ({ dispatch, ID, token }) => {
  const response = await runFetch(dispatch, fetchData, {
    file: 'library',
    ID,
    token
  });
  const bookmarkReq = runFetch(dispatch, fetchBookmarks, { ID, token }).then(
    (bookmarks) => (response.bookmarks = bookmarks)
  );
  const trackReqs = [];
  const objReqs = [];
  for (const objectIds of batchData(response.albums)) {
    if (objectIds.length === 0) continue;
    objReqs.push(runFetch(dispatch, fetchAlbumData, { ID, token, objectIds }));
  }

  for (const objectIds of batchData(response.artists)) {
    if (objectIds.length === 0) continue;
    objReqs.push(runFetch(dispatch, fetchArtistData, { ID, token, objectIds }));
  }

  for (const objectIds of batchData(response.playlists)) {
    if (objectIds.length === 0) continue;
    objReqs.push(
      runFetch(dispatch, fetchPlaylistData, { ID, token, objectIds })
    );
  }

  for (const trackIds of batchData(response.songs)) {
    if (trackIds.length === 0) continue;
    trackReqs.push(runFetch(dispatch, fetchTrackData, { ID, token, trackIds }));
  }

  response.trackInfo = await Promise.all(trackReqs).then((objs) =>
    Object.assign({}, ...objs)
  );
  response.objInfo = await Promise.all(objReqs).then((objs) =>
    Object.assign({}, ...objs)
  );

  await bookmarkReq;
  return response;
};
