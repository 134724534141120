/*
 * index.js
 * author: evan kirkiles
 * created on Thu Feb 2 2023
 * 2023 channel studio
 *
 * Shows the loading page if a user's library is not loaded. Otherwise, shows
 * the children.
 */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { startCheckProgress, useSelectLoadStatus } from 'slices/exploreSlice';
import { LoadState } from 'utils/constants';
import LoadingLibrary from 'views/Loading/LoadingLibrary';

export default function RequireLibrary({ children }) {
  const dispatch = useDispatch();
  const loadStatus = useSelectLoadStatus();
  const libraryLoaded = loadStatus > LoadState.LibraryLocalLoading;
  useEffect(() => {
    dispatch(startCheckProgress());
  }, [dispatch]);

  // if library not yet crawled, return the library loading view
  return (
    <TransitionGroup component={null}>
      <CSSTransition
        key={libraryLoaded ? 'contents' : 'loader'}
        classNames="Transition__fade"
        timeout={200}
      >
        {libraryLoaded ? (
          <main className="Loading__outer">{children}</main>
        ) : (
          <LoadingLibrary loadStatus={loadStatus} />
        )}
      </CSSTransition>
    </TransitionGroup>
  );
}
