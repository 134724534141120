import { checkAuth, startProcess } from 'api/client';
import React, { useCallback, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { toggleDevTools, useSelectDevTools } from 'slices/uiSlice';
import {
  clearUserBackend,
  setUserInfo,
  useSelectUserInfo
} from 'slices/userSlice';

const reducer = (
  { savedID, savedDisplayName, savedToken, savedPlatform },
  { newID, newDisplayName, newToken, newPlatform }
) => ({
  savedID: newID || newID === '' ? newID : savedID,
  savedDisplayName:
    newDisplayName || newDisplayName === '' ? newDisplayName : savedDisplayName,
  savedToken: newToken || newToken === '' ? newToken : savedToken,
  savedPlatform: newPlatform || newPlatform === '' ? newPlatform : savedPlatform
});

export default function DevTools() {
  const dispatch = useDispatch();
  const { ID, displayName, token, platform } = useSelectUserInfo();
  const devTools = useSelectDevTools();
  const [
    { savedID, savedDisplayName, savedToken, savedPlatform },
    updateCredentials
  ] = useReducer(reducer, {
    savedID: ID,
    savedDisplayName: displayName,
    savedToken: token,
    savedPlatform: platform
  });

  useEffect(() => {
    window.__toggleDevTools = () => {
      dispatch(toggleDevTools());
    };
    return () => delete window.__toggleDevTools;
  }, [dispatch]);

  const updateInfo = useCallback(
    () =>
      dispatch(
        setUserInfo({
          frontend_token: savedToken,
          user_id: savedID,
          display_name: savedDisplayName,
          platform: savedPlatform
        })
      ),
    [savedID, savedDisplayName, savedToken, savedPlatform, dispatch]
  );

  if (!devTools) return null;

  return (
    <article className="DevTools">
      <section className="DevTools__userinfo">
        <div>
          user id: {ID && ID.length > 20 ? ID.slice(0, 17) + '...' : ID}
        </div>
        <div>display name: {displayName}</div>
        <div>frontend token: {token}</div>
        <div>
          input a user id:{' '}
          <input
            onChange={({ target: { value } }) =>
              updateCredentials({ newID: value })
            }
            value={savedID || ''}
          />
        </div>
        <div>
          input a display name:{' '}
          <input
            onChange={({ target: { value } }) =>
              updateCredentials({ newDisplayName: value })
            }
            value={savedDisplayName || ''}
          />
        </div>
        <div>
          input a frontend token:{' '}
          <input
            onChange={({ target: { value } }) =>
              updateCredentials({ newToken: value })
            }
            value={savedToken || ''}
          />
        </div>
        <div>
          input the platform:{' '}
          <input
            onChange={({ target: { value } }) =>
              updateCredentials({ newPlatform: value })
            }
            value={savedPlatform || ''}
          />
        </div>
        <div>
          <button onClick={updateInfo}>submit</button>
        </div>
      </section>
      <section className="DevTools__buttons">
        <button onClick={() => dispatch({ type: 'CLEAR_ALL' })}>
          purge persistor
        </button>
        <button onClick={() => checkAuth({ ID, token }).then(console.log)}>
          refresh token
        </button>
        <button onClick={() => startProcess({ ID, token }).then(console.log)}>
          start process
        </button>
        <button
          onClick={() => dispatch(clearUserBackend({ withTracks: false }))}
        >
          reset user progress
        </button>
        <button
          onClick={() =>
            dispatch(clearUserBackend({ withProfile: true, withTracks: false }))
          }
        >
          clear user profile
        </button>
        <button
          onClick={() => dispatch(clearUserBackend({ withTracks: true }))}
        >
          reset user progress and clear track features
        </button>
        <button
          onClick={() =>
            dispatch(clearUserBackend({ withProfile: true, withTracks: true }))
          }
        >
          clear user profile and track features
        </button>
      </section>
    </article>
  );
}
