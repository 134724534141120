import {
  applyMiddleware,
  combineReducers,
  createStore
} from '@reduxjs/toolkit';
import localforage from 'localforage';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import exploreReducer from 'slices/exploreSlice';
import libraryReducer from 'slices/librarySlice';
import playlistReducer, { generatePlaylistSaga } from 'slices/playlistSlice';
import uiReducer from 'slices/uiSlice';
import userReducer from 'slices/userSlice';
import tourReducer from 'slices/tourSlice';
import { watchCheckProgressSaga } from './sagas';

const persistConfig = {
  key: 'root',
  storage: localforage,
  blacklist: ['explore', 'library', 'ui'] 
};

const uiPersistConfig = {
  key: 'ui',
  storage: localforage,
  whitelist: ['devTools']
};

const reducers = combineReducers({
  user: userReducer,
  explore: exploreReducer,
  ui: persistReducer(uiPersistConfig, uiReducer),
  library: libraryReducer,
  playlist: playlistReducer,
  tour: tourReducer
});

export const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_ALL') {
    localforage.removeItem('persist:root');
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk, sagaMiddleware))
);
const persistor = persistStore(store);
// persistor.purge()

// run sagas listening for playlist generation and progress checking
sagaMiddleware.run(generatePlaylistSaga, store);
sagaMiddleware.run(watchCheckProgressSaga, store);

export { store, persistor };
