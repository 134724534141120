import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { getAppleToken } from 'api/client';

export const handlePreviewSong = createAsyncThunk(
  'ui/handlePreviewSong',
  (trackId, { getState }) => {
    const {
      ui: { previewSong, previewPlay }
    } = getState();
    if (previewSong?.id === trackId) {
      return { previewSong: previewSong, previewPlay: !previewPlay };
    }
    const {
      library: {
        library: { trackInfo }
      }
    } = getState();
    const songData = trackInfo[trackId];
    return { previewSong: songData, previewPlay: true };
  }
);

export const fetchAppleToken = createAsyncThunk(
  'user/getAppleToken',
  getAppleToken
);

const initialState = {
  previewSong: {},
  previewPlay: false,
  devTools: false,
  appleDevToken: null,
  librarySearchTerm: '',
  bookmarkSearchTerm: '',
  playlistSearchTerm: '',
  recents: []
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    clearUi: () => initialState,
    pausePreviewPlay: (state) => {
      state.previewPlay = false;
    },
    togglePreviewPlay: (state) => {
      state.previewPlay = !state.previewPlay;
    },
    clearPreviewPlay: (state, action) => {
      state.previewPlay = false;
      state.previewSong = null;
    },
    toggleDevTools: (state, action) => {
      state.devTools = !state.devTools;
    },
    setLibrarySearchTerm: (state, action) => {
      state.librarySearchTerm = action.payload;
    },
    setBookmarkSearchTerm: (state, action) => {
      state.bookmarkSearchTerm = action.payload;
    },
    setPlaylistSearchTerm: (state, action) => {
      state.playlistSearchTerm = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      handlePreviewSong.fulfilled,
      (state, { payload: { previewSong, previewPlay } }) => {
        state.previewSong = previewSong;
        state.previewPlay = previewPlay;
        if (previewPlay) {
          state.recents = [
            previewSong,
            ...state.recents.filter((x) => x.id !== previewSong.id)
          ];
        }
      }
    );
    builder.addCase(
      fetchAppleToken.fulfilled,
      (state, { payload: { token } }) => {
        state.appleDevToken = token;
      }
    );
  }
});

export const {
  pausePreviewPlay,
  togglePreviewPlay,
  clearPreviewPlay,
  toggleDevTools,
  setWindowDimensions,
  setLibrarySearchTerm,
  setBookmarkSearchTerm,
  setPlaylistSearchTerm,
  clearUi
} = uiSlice.actions;

export default uiSlice.reducer;

export const useSelectAppleDevToken = () =>
  useSelector((state) => state.ui.appleDevToken);
export const useSelectPlaylistSearchTerm = () =>
  useSelector((state) => state.ui.playlistSearchTerm);
export const useSelectRecents = () => useSelector((state) => state.ui.recents);
export const useSelectPreviewSong = () =>
  useSelector(({ ui: { previewSong, previewPlay } }) => ({
    previewSong,
    previewPlay
  }));
export const useSelectDevTools = () =>
  useSelector((state) => state.ui.devTools);
