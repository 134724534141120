/*
 * LoadingPersistor.js
 * author: evan kirkiles
 * created on Mon Feb 13 2023
 * 2023 channel studio
 *
 * The loading page while persistance is being added.
 */

import Header from 'components/Header';
import { useLocation } from 'react-router';

export default function LoadingPersistor() {
  const location = useLocation();
  if (location.pathname === '/login') return null;
  return <Header unloaded={true} />;
}
