import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteBookmarks, saveBookmarks } from 'api/client';
import { useSelector } from 'react-redux';
import { staticMode } from 'utils/constants';
import { getLibraryWithTracks, runFetch } from 'utils/sliceUtils';

export const handleBookmark = (params) => async (dispatch) => {
  dispatch(getConnectedIds(params))
    .then((x) => x.payload)
    .then((payload) => {
      if (payload.add) {
        dispatch(addBookmark(payload.ids));
      } else {
        dispatch(removeBookmark(payload.ids));
      }
      dispatch(submitChanges(payload));
    });
};

export const setLibrary = (data) => handleLibrary({ data });

export const handleLibrary = createAsyncThunk(
  'library/handleLibrary',
  async (_, { getState, requestId, dispatch }) => {
    const { user, library } = getState();
    const { ID, token } = user;
    if (library.libraryRequestId !== requestId) {
      return null;
    }
    return getLibraryWithTracks({ dispatch, ID, token });
  },
  {
    condition: (data, { getState }) => {
      if (!data) {
        return false;
      }
      const { library } = getState();
      const { libraryLoaded, libraryRequestId } = library;
      if (libraryLoaded || libraryRequestId) {
        return false;
      }
    }
  }
);

export const submitChanges = createAsyncThunk(
  'library/submitChanges',
  async (payload, { getState, dispatch }) => {
    const { ID, token } = getState().user;
    if (payload.add) {
      runFetch(dispatch, saveBookmarks, {
        tracks: [...new Set(payload.ids)],
        ID,
        token
      });
    } else {
      runFetch(dispatch, deleteBookmarks, {
        tracks: [...new Set(payload.ids)],
        ID,
        token
      });
    }
  },
  {
    condition: () => {
      if (staticMode) {
        return false;
      }
    }
  }
);

export const getConnectedIds = createAsyncThunk(
  'library/getConnectedIds',
  async (itemId, { getState }) => {
    const { library, bookmarks } = getState().library;
    if (!bookmarks.includes(itemId)) {
      return { add: true, ids: [...getToAdd(itemId, library), itemId] };
    }
    return { add: false, ids: [...getToRemove(itemId, library), itemId] };
  }
);

const getToAdd = (itemId, { trackInfo, objInfo }) => {
  if (trackInfo[itemId]) {
    return [itemId];
  }
  const trackList = objInfo[itemId]?.track_list;
  if (!trackList) {
    return [itemId];
  }
  return trackList;
};

const getToRemove = (itemId, { trackInfo, objInfo, parents }) => {
  if (trackInfo[itemId]) {
    return parents[itemId];
  }
  const trackList = objInfo[itemId]?.track_list;
  if (trackList) {
    const toRemove = [];
    trackList.forEach((trackId) => {
      toRemove.push(trackId);
      if (parents[trackId]) {
        toRemove.push(...parents[trackId]);
      }
    });
    return toRemove;
  }
};

const initialState = {
  bookmarks: [],
  library: {},
  libraryCrawled: false,
  libraryLoaded: false,
  libraryRequestId: null
};

export const librarySlice = createSlice({
  name: 'library',
  initialState,
  reducers: {
    clearLibrary: () => initialState,
    setLibraryCrawled: (state, action) => {
      state.libraryCrawled = action.payload;
    },
    addBookmark: (state, action) => {
      state.bookmarks = [...new Set([...state.bookmarks, ...action.payload])];
    },
    removeBookmark: (state, action) => {
      state.bookmarks = state.bookmarks.filter(
        (x) => !action.payload.includes(x)
      );
    }
  },
  extraReducers: (builder) => {
    builder.addCase(handleLibrary.fulfilled, (state, { payload }) => {
      if (state.libraryLoaded) {
        return;
      }
      state.libraryRequestId = null;
      if (!payload) {
        return;
      }
      const {
        songs,
        albums,
        artists,
        playlists,
        parents,
        trackInfo,
        objInfo,
        bookmarks
      } = payload;
      state.library = {
        songs: songs,
        albums: albums,
        artists: artists,
        playlists: playlists,
        parents: parents,
        trackInfo: trackInfo,
        objInfo: objInfo
      };
      state.bookmarks = bookmarks;
      state.libraryLoaded = true;
    });
    builder.addCase(handleLibrary.pending, (state, { meta }) => {
      const { arg, requestId } = meta;
      if (arg.data) {
        state.libraryRequestId = requestId;
      }
    });
    builder.addCase(handleLibrary.rejected, (state, { meta }) => {
      if (state.libraryRequestId === meta.requestId) {
        state.libraryRequestId = null;
      }
    });
  }
});

export const { addBookmark, removeBookmark, setLibraryCrawled, clearLibrary } =
  librarySlice.actions;

export default librarySlice.reducer;

export const useSelectLibrarySongs = () =>
  useSelector((state) => state.library.library.songs);
export const useSelectTrackInfo = () =>
  useSelector((state) => state.library.library.trackInfo);
export const useSelectLibraryLoaded = () =>
  useSelector((state) => state.library.libraryLoaded);
export const useSelectBookmarks = () =>
  useSelector((state) => state.library.bookmarks);
