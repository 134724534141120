/*
 * NavLink.js
 * author: evan kirkiles
 * created on Thu May 11 2023
 * 2023 channel studio
 */
import { Link, useLocation } from 'react-router-dom';
import { setToured, useSelectToured } from 'slices/tourSlice';
import { useDispatch } from 'react-redux';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import classnames from 'classnames';

export default function NavLink({ text, path, tourState, disabled }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const isToured = useSelectToured(tourState);

  return (
    <li
      className={classnames('Nav__item', {
        'Nav__item-active': location.pathname === path
      })}
    >
      <Link
        id={`NavLink_${text}`}
        className={classnames('ClickItem', 'ClickItem__underline', {
          'ClickItem-active': location.pathname === path,
          'ClickItem-disabled': disabled
        })}
        to={path}
      >
        <span
          className={text === 'Playlist' ? 'Nav__playlist-generator' : null}
        >
          {text}
          {isToured ? (
            <button
              className={classnames('ClickItem', 'Nav__tutorialtrigger', {
                'Nav__tutorialtrigger-active': location.pathname === path
              })}
              onClick={() => {
                dispatch(setToured({ item: tourState, toured: false }));
              }}
            >
              <AiOutlineInfoCircle />
            </button>
          ) : null}
        </span>
      </Link>
    </li>
  );
}
