import App from 'App';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorker from 'serviceWorker';
import { persistor, store } from 'store';
import LoadingPersistor from 'views/Loading/LoadingPersistor';

// initialize tag manager if GTM id is present
if (process.env.REACT_APP_GOOGLE_GTM_ID) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GOOGLE_GTM_ID
  });
}

//attaching this to window temporarily
window.___clearpersist = () => {
  store.dispatch({ type: 'CLEAR_ALL' });
};

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={<LoadingPersistor />} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
