import { unmarshall } from '@aws-sdk/util-dynamodb';

const handleResponse = (response) => {
  if (response.ok) {
    return response;
  }
  switch (response.status) {
    case 401:
    case 403:
      return response;
    //  other response types can be handled here
    //  case ???: return something
    default:
      console.error(response.statusText);
      return { errored: true, status: response.status };
  }
};

const scope = [
  'user-read-email',
  'user-library-read',
  'playlist-read-private',
  'playlist-read-collaborative',
  'playlist-modify-public'
].join(' ');

const url = new URL('authorize', 'https://accounts.spotify.com');
url.searchParams.append('response_type', 'code');
url.searchParams.append('client_id', process.env.REACT_APP_CLIENT_ID);
url.searchParams.append(
  'redirect_uri',
  new URL('authorization/login/', process.env.REACT_APP_BACKEND_URL)
);
url.searchParams.append('scope', scope);
export const loginURL = url.href;

const buildURL = (params) => {
  const {
    ID,
    endpoint,
    source,
    target,
    file,
    withTracks,
    withProfile,
    appleToken,
    trackIds,
    objectIds
  } = params;
  const url = new URL(endpoint, process.env.REACT_APP_BACKEND_URL);
  if (ID) {
    url.searchParams.append('user_id', ID);
  }
  if (source) {
    url.searchParams.append('source', source);
  }
  if (target) {
    url.searchParams.append('target', target);
  }
  if (file) {
    url.searchParams.append('file', file);
  }
  if (withTracks) {
    url.searchParams.append('with_tracks', withTracks);
  }
  if (withProfile) {
    url.searchParams.append('with_profile', withProfile);
  }
  if (appleToken) {
    url.searchParams.append('apple_token', appleToken);
  }
  if (trackIds) {
    url.searchParams.append('track_ids', trackIds.join(','));
  }
  if (objectIds) {
    url.searchParams.append('ids', objectIds.join(','));
  }
  return url;
};

export const getAppleToken = async () =>
  await window
    .fetch(buildURL({ endpoint: 'authorization/get_apple_token' }))
    .then((r) => r.json());

export const loginWithApple = async ({ ID, token, appleToken }) => {
  return window
    .fetch(
      buildURL({ endpoint: 'authorization/save_apple_info', ID, appleToken }),
      { method: 'POST', headers: { Authorization: token } }
    )
    .then((r) => r.ok)
    .catch((e) => console.error(e));
};

export const checkAuth = async ({ ID, token }) => {
  return window
    .fetch(buildURL({ endpoint: 'authorization/validate', ID }), {
      method: 'GET',
      headers: { Authorization: token }
    })
    .then((r) => r.ok)
    .catch((e) => console.error(e));
};

export const checkProgress = async ({ ID, token }) => {
  return window
    .fetch(buildURL({ endpoint: 'data/user_progress', ID }), {
      method: 'GET',
      headers: { Authorization: token }
    })
    .then(handleResponse)
    .then((r) => r.json())
    .then((j) => unmarshall(j))
    .catch((e) => console.error(e));
};

export const startProcess = async ({ ID, token }) => {
  return window
    .fetch(buildURL({ endpoint: 'execution/start_process', ID }), {
      method: 'POST',
      headers: { Authorization: token }
    })
    .then(handleResponse)
    .then((r) => r.json())
    .catch((e) => console.error(e));
};

export const fetchTrackData = async ({ ID, token, trackIds }) => {
  return window
    .fetch(buildURL({ endpoint: 'data/tracks', ID, trackIds }), {
      method: 'GET',
      headers: {
        Authorization: token,
        'Accept-Encoding': 'gzip'
      }
    })
    .then(handleResponse)
    .then((r) => r.json())
    .then((j) =>
      j
        .map((track) => unmarshall(track))
        .map(
          ({
            track_id,
            name,
            album_id,
            album_name,
            artist_id,
            artist_name,
            images,
            isrc,
            preview_url,
            release_year,
            duration
          }) => [
            track_id,
            {
              id: track_id,
              name,
              album_id,
              album_name,
              artist_id,
              artist_name,
              isrc,
              preview_url,
              release_year,
              duration,
              ...images
            }
          ]
        )
    )
    .then((j) => Object.fromEntries(j))
    .catch((e) => console.error(e));
};
export const fetchObjectData = async ({ ID, token, objectIds }) => {
  return window
    .fetch(buildURL({ endpoint: 'data/objects', ID, objectIds }), {
      method: 'GET',
      headers: {
        Authorization: token,
        'Accept-Encoding': 'gzip'
      }
    })
    .then(handleResponse)
    .then((r) => r.json())
    .then((j) => j.map((track) => unmarshall(track)));
};
export const fetchAlbumData = async ({ ID, token, objectIds }) => {
  return fetchObjectData({ ID, token, objectIds })
    .then((j) =>
      j.map(
        ({
          id,
          name,
          artist_id,
          artist_name,
          preview_url,
          images,
          release_year,
          track_list
        }) => [
          id,
          {
            id,
            name,
            artist_id,
            artist_name,
            preview_url,
            release_year,
            track_list,
            ...images
          }
        ]
      )
    )
    .then((j) => Object.fromEntries(j))
    .catch((e) => console.error(e));
};
export const fetchArtistData = async ({ ID, token, objectIds }) => {
  return fetchObjectData({ ID, token, objectIds })
    .then((j) =>
      j.map(({ id, name, url, images, track_list }) => [
        id,
        {
          id,
          name,
          url,
          track_list,
          ...images
        }
      ])
    )
    .then((j) => Object.fromEntries(j))
    .catch((e) => console.error(e));
};
export const fetchPlaylistData = async ({ ID, token, objectIds }) => {
  return fetchObjectData({ ID, token, objectIds })
    .then((j) =>
      j.map(
        ({
          id,
          name,
          url,
          description,
          owner_id,
          owner_name,
          images,
          track_list
        }) => [
          id,
          {
            id,
            name,
            owner_id,
            owner_name,
            description,
            url,
            track_list,
            ...images
          }
        ]
      )
    )
    .then((j) => Object.fromEntries(j))
    .catch((e) => console.error(e));
};

export const fetchData = async ({ file, ID, token }) => {
  return window
    .fetch(buildURL({ endpoint: 'files', ID, file }), {
      method: 'GET',
      headers: {
        Authorization: token,
        'Accept-Encoding': 'gzip'
      }
    })
    .then(handleResponse)
    .then((r) => r.json())
    .catch((e) => console.error(e));
};

export const checkBackendVersion = async ({ ID, token }) => {
  return window
    .fetch(buildURL({ endpoint: 'data/user_version', ID }), {
      method: 'GET',
      headers: { Authorization: token }
    })
    .then(handleResponse)
    .then((r) => r.json())
    .catch((e) => console.error(e));
};

export const fetchBookmarks = async ({ ID, token }) => {
  return window
    .fetch(process.env.REACT_APP_APPSYNC_URL, {
      method: 'POST',
      headers: {
        Authorization: `${ID} ${token}`,
        'Accept-Encoding': 'gzip'
      },
      body: JSON.stringify({
        query: `query {
                  getBookmarks(id: "${ID}") {
                    id
                    bookmarks
                  }
                }`
      })
    })
    .then(handleResponse)
    .then((r) => r.json())
    .then(
      ({
        data: {
          getBookmarks: { bookmarks }
        }
      }) => bookmarks.filter((bmark) => bmark !== '')
    )
    .catch((e) => console.error(e));
};

export const saveBookmarks = async ({ tracks, ID, token }) => {
  return window
    .fetch(process.env.REACT_APP_APPSYNC_URL, {
      method: 'POST',
      headers: {
        Authorization: `${ID} ${token}`,
        'Accept-Encoding': 'gzip'
      },
      body: JSON.stringify({
        query: `mutation UpdateBookmarks($input: UpdateBookmarksInput!) {
                  updateBookmarks(input: $input) {
                    id
                    bookmarks
                  }
                }`,
        variables: {
          input: {
            id: ID,
            operation: 'ADD',
            bookmarks: tracks
          }
        }
      })
    })
    .then(handleResponse)
    .then((r) => r.json())
    .catch((e) => console.error(e));
};

export const deleteBookmarks = async ({ tracks, ID, token }) => {
  return window
    .fetch(process.env.REACT_APP_APPSYNC_URL, {
      method: 'POST',
      headers: {
        Authorization: `${ID} ${token}`,
        'Accept-Encoding': 'gzip'
      },
      body: JSON.stringify({
        query: `mutation UpdateBookmarks($input: UpdateBookmarksInput!) {
                  updateBookmarks(input: $input) {
                    id
                    bookmarks
                  }
                }`,
        variables: {
          input: {
            id: ID,
            operation: 'DELETE',
            bookmarks: tracks
          }
        }
      })
    })
    .then(handleResponse)
    .then((r) => r.json())
    .catch((e) => console.error(e));
};

export const getPath = async ({ source, target, ID, token }) => {
  return window
    .fetch(buildURL({ endpoint: 'execution/get_path', ID, source, target }), {
      method: 'GET',
      headers: { Authorization: token }
    })
    .then(handleResponse)
    .then((r) => r.json())
    .catch((e) => console.error(e));
};

export const clearUser = async ({ ID, token, withTracks, withProfile }) => {
  return window
    .fetch(
      buildURL({
        endpoint: 'execution/clear_user',
        ID,
        withTracks,
        withProfile
      }),
      {
        method: 'POST',
        headers: { Authorization: token }
      }
    )
    .then(handleResponse)
    .then((r) => r.json())
    .catch((e) => console.error(e));
};

export const makePlaylist = async ({
  tracks,
  ID,
  token,
  name,
  platform,
  description
}) => {
  return window
    .fetch(buildURL({ endpoint: 'collection/make_playlist', ID }), {
      method: 'POST',
      headers: { Authorization: token },
      body: JSON.stringify({
        platform: platform,
        track_ids: tracks,
        playlist_name: name,
        playlist_description: description,
        playlist_public: true
      })
    })
    .then(handleResponse)
    .then((r) => r.json())
    .catch((e) => console.error(e));
};
