import { applyHighlight } from 'utils/searchUtils';

export const getMediumImage = (obj) => {
  const { image_None } = obj;
  if (image_None || !obj) {
    return image_None;
  }
  return Object.entries(obj)
    .filter(([key]) => key.startsWith('image'))
    .map(([key, value]) => [parseInt(key.split('_')[1]), value])
    .filter(([key]) => key <= 350)
    .map(([key, value]) => ({ key, value }))
    .reduce((a, b) => (a.key > b.key ? a : b), { key: 0, value: undefined })
    .value;
};

export const expandLibraryObject = (objId, objInfo, trackInfo) => {
  const obj = objInfo[objId];
  return {
    ...obj,
    track_list: obj.track_list.map((trackId) => trackInfo[trackId])
  };
};

export function mapSongs({ items, searchTerms = [], clusterRanks = {} }) {
  return items.reduce((acc, song) => {
    if (!song) return acc;
    const {
      id,
      name,
      artist_name,
      album_name,
      release_year,
      image_64,
      duration
    } = song;
    acc.push({
      type: 'songs',
      play_preview: id,
      images: {
        type: 'track',
        id: id,
        imageUrl: image_64 || 'missing_track_art_64.jpg'
      },
      name: applyHighlight({ str: name, searchTerms: searchTerms }),
      artist: applyHighlight({ str: artist_name, searchTerms: searchTerms }),
      album: applyHighlight({ str: album_name, searchTerms: searchTerms }),
      release_date: applyHighlight({
        str: release_year,
        searchTerms: searchTerms
      }),
      duration: duration,
      owner_name: null,
      save: id,
      cluster_rank: clusterRanks[id]?.rank ?? null,
      subRows: null
    });
    return acc;
  }, []);
}

export function mapAlbums({ items, searchTerms }) {
  return items.map((album) => {
    const { id, name, artist_name, track_list, image_300, release_year } =
      album;
    return {
      type: 'albums',
      play_preview: null,
      images: {
        type: 'album',
        id: id,
        imageUrl: image_300 || 'missing_track_art_300.jpg'
      },
      name: applyHighlight({ str: name, searchTerms: searchTerms }),
      artist: applyHighlight({ str: artist_name, searchTerms: searchTerms }),
      album: applyHighlight({ str: name, searchTerms: searchTerms }),
      release_date: parseInt(release_year),
      owner_name: null,
      save: id,
      subRows: mapSongs({ items: track_list, searchTerms: searchTerms })
    };
  });
}

export function mapPlaylists({ items, searchTerms }) {
  return items.map((playlist) => {
    const { id, name, owner_name, track_list } = playlist;

    return {
      type: 'playlists',
      play_preview: null,
      images: {
        type: 'playlist',
        id: id,
        imageUrl:
          getMediumImage(playlist) ||
          track_list?.[0]?.image_300 ||
          'missing_track_art_300.jpg'
      },
      name: applyHighlight({ str: name, searchTerms: searchTerms }),
      owner_name: applyHighlight({ str: owner_name, searchTerms: searchTerms }),
      artist: null,
      album: null,
      release_date: null,
      save: id,
      subRows: mapSongs({ items: track_list, searchTerms: searchTerms })
    };
  });
}

export function mapArtists({ items, searchTerms }) {
  return items.map((artist) => {
    const { id, name, track_list } = artist;
    return {
      type: 'artists',
      play_preview: null,
      images: {
        type: 'artist',
        id: id,
        imageUrl: getMediumImage(artist) || 'missing_track_art_300.jpg'
      },
      name: applyHighlight({ str: name, searchTerms: searchTerms }),
      artist: null,
      album: null,
      release_date: null,
      owner_name: null,
      save: id,
      subRows: mapSongs({ items: track_list, searchTerms: searchTerms })
    };
  });
}
