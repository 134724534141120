import classNames from 'classnames';
import React from 'react';

export default function Logo({ className = '' }) {
  return (
    <svg
      viewBox="0 0 1856 662"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('Icon', className)}
    >
      <path
        d="M1790.31 132.709H1788.56C1752.37 132.709 1723.08 103.38 1723.08 67.2334V65.4754C1723.08 29.286 1693.75 0 1657.6 0H1523.4C1487.21 0 1457.92 29.3288 1457.92 65.4754V67.2334C1457.92 103.423 1428.59 132.709 1392.44 132.709C1356.26 132.709 1326.97 103.38 1326.97 67.2334V65.4754C1326.97 29.286 1297.64 0 1261.49 0H1127.58C1091.4 0 1062.11 29.3288 1062.11 65.4754V67.2334C1062.11 103.423 1032.78 132.709 996.634 132.709H994.876C958.686 132.709 929.4 162.038 929.4 198.184V202.086C929.4 238.275 958.729 267.561 994.876 267.561H997.706C1033.9 267.561 1063.18 238.233 1063.18 202.086V199.256C1063.18 163.067 1092.51 133.781 1128.66 133.781H1260.04C1296.23 133.781 1325.51 163.11 1325.51 199.256V596.482C1325.51 632.671 1354.84 661.957 1390.99 661.957H1393.82C1430.01 661.957 1459.29 632.628 1459.29 596.482V199.299C1459.29 163.11 1488.62 133.824 1524.77 133.824H1656.49C1692.68 133.824 1721.97 163.152 1721.97 199.299V596.525C1721.97 632.714 1751.29 662 1787.44 662H1790.27C1826.46 662 1855.75 632.671 1855.75 596.525V198.227C1855.83 162.038 1826.5 132.709 1790.31 132.709Z"
        fill="currentColor"
      />
      <path
        d="M929.596 325.618C929.596 289.986 900.739 261.129 865.107 261.129H860.819C825.23 261.129 796.33 289.986 796.33 325.618V329.906C796.33 365.538 825.187 394.395 860.819 394.395H865.107C900.696 394.395 929.596 365.538 929.596 329.906V325.618Z"
        fill="currentColor"
      />
      <path
        d="M729.971 394.396H724.611C688.979 394.396 660.122 423.253 660.122 458.885V463.173C660.122 498.805 631.265 527.662 595.633 527.662H531.143H397.877H264.654H198.021C162.389 527.662 133.532 498.805 133.532 463.173V396.582V394.438V265.46V263.316V198.827C133.532 163.195 162.389 134.338 198.021 134.338H202.309H264.654H397.92H531.186H593.789H595.675C630.922 134.338 660.936 162.638 660.165 197.927C659.393 233.859 688.679 263.273 724.654 263.273H730.014C774.135 263.273 808.267 220.352 792.916 174C784.083 147.33 758.142 130.007 730.014 130.007H728.942C692.967 130.007 663.852 100.507 664.452 64.4032C665.053 29.2429 635.124 1.07178 599.963 1.07178H598.077H593.789H531.186H397.877H264.654H202.309H198.021H195.877C160.245 1.07178 131.388 29.929 131.388 65.5609V67.7048C131.388 103.337 102.531 132.194 66.8987 132.194H64.7548C29.1228 132.194 0.265625 161.051 0.265625 196.683V263.316V265.46V325.661V329.949V394.438V396.582V463.215C0.265625 498.847 29.1228 527.705 64.7548 527.705H66.8987C102.531 527.705 131.388 556.562 131.388 592.194V596.482C131.388 632.114 160.245 660.971 195.877 660.971H264.654H397.92H531.186H599.963C635.595 660.971 664.452 632.114 664.452 596.482V592.194C664.452 556.605 693.31 527.705 728.942 527.705H730.014C766.803 527.705 796.647 497.861 796.647 461.072C796.647 424.282 766.76 394.396 729.971 394.396Z"
        fill="currentColor"
      />
      <path
        d="M1130.91 525.431C1094.72 525.431 1065.39 496.102 1065.39 459.913C1065.39 423.724 1036.06 394.395 999.873 394.395H995.114C958.925 394.395 929.596 423.724 929.596 459.913V464.63C929.596 500.819 958.925 530.148 995.114 530.148C1031.3 530.148 1060.63 559.477 1060.63 595.666C1060.63 631.855 1089.96 661.184 1126.15 661.184H1130.87C1167.06 661.184 1196.39 631.855 1196.39 595.666V590.949C1196.39 554.76 1167.06 525.431 1130.91 525.431Z"
        fill="currentColor"
      />
    </svg>
  );
}
