import { useState, useRef, useCallback } from 'react';

/**
 * This hook handles lazy loading of modules.
 *
 * It takes an array of module path resolvers (e.g. () => import('./module/path'))
 * and loads each one into an array of results, which the consumer can then
 * pull from and use as desired.
 *
 * @param {array} resolvers The module path resolvers (e.g. () => import('./module/path'))
 * @returns {array} An array containing: `isLoading`, a boolean representing the
 * loading state; `load`, the function used to initialize loading; `result`, an
 * array of either the resolved modules or the error if there was one.
 * @example
 * import useLazyLoad from '../../../hooks/useLazyLoad';
 * const lazyComponent = () => import('./Component/Component');
 *
 * // Then, within the parent component, set up the hook...
 * const [isLoading, load, [Component]] = useLazyLoad([lazyComponent]);
 *
 * // Then, initialize loading somewhere...
 * async function handleClick() {
 *   await load();
 *   // Do stuff that depends on `Component` being loaded.
 * }
 *
 * // Probably show some loading state...
 * {isLoading && <Loading />}
 *
 * // Show the component when it's loaded...
 * {Component && <Component />}
 */
export function useLazyLoad(resolvers) {
  const [isLoading, setIsLoading] = useState(false);
  const result = useRef();

  // Always return array with same length as the number of components so the
  // hook's consumer can immediately destructure, for example:
  // const [loading, load, [Comp1, Comp2]] = useLazyLoad([lazyComp1, lazyComp2]);
  const placeholderResult = useRef(Array(resolvers.length));

  const load = useCallback(async () => {
    // Do nothing if modules have already been loaded.
    if (result.current) return;

    try {
      setIsLoading(true);

      // Resolve each module.
      const modulePromises = resolvers.map((resolver) => resolver());
      const modules = await Promise.all(modulePromises);

      // If the module has a default export, return it directly,
      // Otherwise, return the entire object and let consumer handle it.
      result.current = modules.map((module) =>
        'default' in module ? module.default : module
      );
    } catch (error) {
      // Here we just return the error in an array. You may want different error handling depending on your use case.
      result.current = [{ error }];
    } finally {
      setIsLoading(false);
    }
  }, []);

  return [isLoading, load, result.current || placeholderResult.current];
}
