/*
 * AboutPane.js
 * author: evan kirkiles
 * created on Thu Feb 02 2023
 * 2023 channel studio
 */
import classNames from 'classnames';
import { VscArrowLeft } from 'react-icons/vsc';

export default function AboutPane({ active, deactivate, pageMode = false }) {
  return (
    <ul
      className={classNames('Menu__subview', 'Menu__about', 'Menu__items', {
        'Menu__subview-active': active,
        AboutPage: pageMode
      })}
    >
      {!pageMode && (
        <>
          <li className="Menu__subview_backbutton Menu__about_action">
            <button onClick={deactivate}>
              <VscArrowLeft />
            </button>
          </li>
          <li className="Menu__about_header">
            <button onClick={deactivate}>
              About <span>Kranzler Music</span>
            </button>
          </li>
        </>
      )}
      {pageMode && <li className="AboutPage__title">About Kranzler Music</li>}
      <li className="Menu__about_body">
        <p>
          Kranzler Music is an experimental music browser that uses machine
          learning to surface musical relationships among a collection of songs.
          By inventing new interfaces for browsing, our hope is to help people
          explore their libraries in a way they haven’t been able to before,
          discover less obvious connections among songs, and add a new dimension
          of play to that most pressing question—what should I listen to next?
        </p>
        <h1>Background</h1>
        <p>
          Devised by Seth Kranzler, Kranzler Music started in 2017 as an
          open-ended research project in collaboration with Sam Valenti of
          Ghostly International with the idea of creating a tool for people to
          explore a collection of music based on what each song actually sounds
          like. Traditional music organization systems often use concepts of
          genre or time period to sort music beyond artist or album. While these
          have been successful methods of organization since the beginning of
          recorded music, most classification systems still don’t take account
          of how the music actually <em>sounds</em>.
        </p>
        <p className="Menu__about_body_smaller_p">
          In technical terms, the project is centered around a method called{' '}
          <em>feature extraction</em>, where a neural network that has been
          trained to classify songs by genre, mood, and instrumentation is
          augmented to create audio fingerprints–long series of thousands of
          numbers that each represent some abstract aspect of the music.
          Applying this process to the 30 second samples for each song in a
          collection results in a multidimensional space where other machine
          learning methods can then be applied.
        </p>
        <p className="Menu__about_body_smaller_p">
          Why is all this technical detail so important? This is Kranzler
          Music’s <em>raison d'etre</em>–where it significantly differentiates
          from the standard browsing experience and widespread user-based
          recommendation systems. In exploring your library, you may find rap
          songs that are closer to metal, and metal tracks that neighbor
          classical. Vocal interludes of whatever genre are grouped together,
          instead of by album or artist. That moment when a DJ magically pairs
          two musically compatible songs from vastly different worlds? Now it’s
          at your fingertips.
        </p>
        <h1>How It Works</h1>
        <p>
          After logging in, Kranzler Music crawls your streaming library to make
          a list of all songs, albums, artists, and playlists you’ve saved.
        </p>
        <ul className="Menu__about_body_bulleted">
          <li>
            Once it’s gathered this list, the app streams each 30 second song
            preview through a machine learning model to generate feature vectors
            (think of these as unique audio fingerprints)
          </li>
          <li>
            These fingerprints consist of very long lists of numbers, with each
            number corresponding to some unique attribute the machine learning
            model has learned in order to understand the differences and
            similarities between songs
          </li>
        </ul>
        <p>
          Once feature extraction is complete, the app then calculates the{' '}
          <em>Map</em>, <em>Cluster</em>, and <em>Playlist Generator</em>{' '}
          information in parallel.
        </p>
        <ul className="Menu__about_body_bulleted">
          <li>
            In order to create the Map view, a two dimensional layout is created
            using{' '}
            <a
              href="https://umap-learn.readthedocs.io/en/latest/"
              target="_blank"
              rel="noopener noreferrer"
            >
              UMAP
            </a>{' '}
            to reduce the 4096 dimensions of the feature vectors down to 2
            dimensions
          </li>
          <li>
            We then use Mario Klingemann’s{' '}
            <a
              href="https://github.com/Quasimondo/RasterFairy"
              target="_blank"
              rel="noopener noreferrer"
            >
              RasterFairy
            </a>{' '}
            to make a grid version of this scattered layout.
          </li>
          <li>
            The Cluster view is created using{' '}
            <a
              href="https://scikit-learn.org/stable/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Scikit
            </a>
            ’s implementation of{' '}
            <a
              href="https://github.com/scikit-learn-contrib/hdbscan"
              target="_blank"
              rel="noopener noreferrer"
            >
              HDBSCAN
            </a>{' '}
            and is largely automated–we do not tell the application how many
            clusters to create.
          </li>
          <li>
            Finally, we create a graph of all the songs using their extracted
            features and nearest neighbors
          </li>
        </ul>
        <p>
          In much the same way as map routing software, Kranzler Music uses{' '}
          <a
            href="https://en.wikipedia.org/wiki/Dijkstra's_algorithm"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dijkstra’s algorithm
          </a>{' '}
          on this generated graph in order to find the shortest paths between
          two songs
        </p>
        <h1>Why is it taking so long?</h1>
        <p>
          Kranzler Music isn’t your standard web application. Powering the
          interface and experience are a series of complex algorithms deployed
          via scalable serverless applications. Each of the processes outlined
          above require computational resources that must be performed in real
          time after a user has logged in, all of which takes time to process.
        </p>
        <p>
          In addition to the machine learning inference and data science
          processing, the crawling of user libraries via the Spotify or Apple
          Music API is rate limited. The larger the library, the longer this
          takes. As more people log into the app, cached song features should
          help speed things up.
        </p>
        {/* <p>
          Lastly, Channel Music is still in Beta as we work through bugs related
          to scaling. You can help us out by reporting bugs{' '}
          <a
            href="https://forms.gle/W91qhk3RnCYP8cZm7"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </p> */}
        <h2>Special Thanks:</h2>
        <ul>
          <li>Ghostly/Sam Valenti</li>
          <li>
            <a
              href="https://github.com/jordipons/musicnn"
              target="_blank"
              rel="noopener noreferrer"
            >
              Musicnn authors
            </a>
          </li>
          <li>Mario Klingemann</li>
        </ul>
        {/* <h1>About Channel Studio</h1>
        <p>
          Channel is an independent brand and user experience innovation company
          whose clients include Google, The New York Times, Mount Sinai, and the
          Nobel Prize. Originally launched out of the New Museum in New York
          City, Channel is carving out a vanguard position by combining
          intensive technology with formal design expertise. Through their
          design and build work, Channel frequently discovers IP and processes
          that lend themselves to productization. Today Channel partners with
          global brands, technology startups, and cultural institutions to
          imagine futuristic interfaces, and build them at scale.
        </p>
        <p>
          <a
            href="https://channel.studio"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.channel.studio
          </a>
        </p> */}
      </li>
    </ul>
  );
}
