/*
 * index.js
 * author: evan kirkiles
 * created on Sat Jan 14 2023
 * 2023 channel studio
 *
 * Protects a route, requiring the user to be logged in to view it.
 */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSelectLoggedIn } from 'slices/userSlice';

export default function RequireAuth({ children }) {
  const loggedIn = useSelectLoggedIn();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loggedIn) {
      dispatch({ type: 'CLEAR_ALL' });
      navigate('/login', { replace: true });
    }
  }, [dispatch, navigate, loggedIn]);
  return loggedIn ? children : null;
}
