/*
 * NavLoader.js
 * author: evan kirkiles
 * created on Wed Feb 15 2023
 * 2023 channel studio
 */

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useSelectExplore } from 'slices/exploreSlice';
import { LoadMessages, LoadState } from 'utils/constants';

export default function NavLoader({ loadState, className }) {
  const { progress, total } = useSelectExplore();
  const progressPercent =
    loadState === LoadState.ExtractingTracks ? progress / total : 0.35;

  return (
    <Link to={'/'}>
      <div className={classNames('Nav__inner', 'Nav__loader', className)}>
        <span className="Nav__loaderstate">
          <svg className="Nav__loaderprogress" viewBox="0 0 44 44">
            <circle
              stroke="#333333"
              strokeWidth="4"
              fill="transparent"
              r="18"
              cx="22"
              cy="22"
            />
            <circle
              className={classNames('Nav__loaderprogress-wheel', {
                'Nav__loaderprogress-wheel-infinite':
                  loadState !== LoadState.ExtractingTracks
              })}
              stroke="white"
              strokeWidth="4"
              fill="transparent"
              r="18"
              cx="22"
              cy="22"
              style={{
                strokeDashoffset: `${(1 - progressPercent) * 2 * 18 * 3.14}`
              }}
            />
          </svg>
          <SwitchTransition>
            <CSSTransition
              key={loadState}
              appear
              timeout={300}
              classNames={'Transition__fadeupswitch'}
            >
              <p>{LoadMessages[loadState][0]}</p>
            </CSSTransition>
          </SwitchTransition>
        </span>
        <span>
          {loadState === LoadState.ExtractingTracks
            ? `${Math.round((100 * progress) / total).toLocaleString()}%`
            : ''}
        </span>
      </div>
    </Link>
  );
}
