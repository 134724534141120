import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { clearUser, loginWithApple } from 'api/client';
import { useSelector } from 'react-redux';
import { clearExplore, startCheckProgress } from 'slices/exploreSlice';
import { clearLibrary } from 'slices/librarySlice';
import { clearPlaylist } from 'slices/playlistSlice';
import { clearUi } from 'slices/uiSlice';
import { runFetch } from 'utils/sliceUtils';

export const clearUserBackend = createAsyncThunk(
  'user/clearUserBackend',
  async ({ withTracks, withProfile }, { getState, dispatch }) => {
    const {
      user: { token, ID }
    } = getState();
    await runFetch(dispatch, clearUser, { ID, token, withTracks, withProfile });
    if (withProfile) {
      dispatch({ type: 'CLEAR_ALL' });
      return true;
    }
    await Promise.all([
      dispatch(clearLibrary()),
      dispatch(clearExplore()),
      dispatch(clearUi()),
      dispatch(clearPlaylist())
    ]);
    await dispatch(startCheckProgress());
    return true;
  }
);

export const submitAppleMusicToken = createAsyncThunk(
  'user/submitAppleMusicToken',
  async ({ appleToken }, { getState }) => {
    const {
      user: { token, ID }
    } = getState();
    return await loginWithApple({ ID, token, appleToken });
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    ID: null,
    token: null,
    displayName: null,
    loggedIn: false,
    platform: null
  },
  reducers: {
    clearPlatform: (state) => {
      state.platform = null;
    },
    setUserInfo: (
      state,
      {
        payload: {
          user_id,
          display_name,
          frontend_token,
          backend_platform,
          needs_login = false
        }
      }
    ) => {
      if (user_id) {
        state.ID = user_id;
      }
      if (display_name) {
        state.displayName = display_name;
      }
      if (frontend_token) {
        state.token = frontend_token;
      }
      if (backend_platform) {
        state.platform = backend_platform;
      }
      state.loggedIn = !!frontend_token && !needs_login;
    },
    clearMusicKit: (state) => {
      Object.keys(localStorage)
        .filter(
          (k) =>
            k.startsWith('music.') ||
            k.startsWith(state.displayName) ||
            k === 'mk-player-tsid'
        )
        .forEach((k) => {
          localStorage.removeItem(k);
        });
    }
  },
  extraReducers: (builder) => {
    builder.addCase(submitAppleMusicToken.fulfilled, (state, { payload }) => {
      state.loggedIn = payload;
    });
  }
});

export const { clearPlatform, setUserInfo, clearMusicKit } = userSlice.actions;

export default userSlice.reducer;

export const useSelectUserId = () => useSelector((state) => state.user.ID);
export const useSelectDisplayName = () =>
  useSelector((state) => state.user.displayName);
export const useSelectPlatform = () =>
  useSelector((state) => state.user.platform);
export const useSelectLoggedIn = () =>
  useSelector((state) => state.user.loggedIn);
export const useSelectUserInfo = () =>
  useSelector(({ user: { ID, displayName, token, platform } }) => ({
    ID,
    displayName,
    token,
    platform
  }));
