import { checkProgress, checkBackendVersion, startProcess } from 'api/client';
import {
  call,
  cancel,
  delay,
  fork,
  put,
  take,
  takeLeading
} from 'redux-saga/effects';
import {
  setAtlas,
  setCluster,
  setGraph,
  setGrid,
  setPreprocess,
  setProgress,
  setTotal,
  setErrored,
  startCheckProgress,
  stopCheckProgress
} from 'slices/exploreSlice';
import { handleLibrary, setLibraryCrawled } from 'slices/librarySlice';
import { clearUserBackend } from 'slices/userSlice';

const DELAY_TIME_MS = 1000;

const FUNCTION_MAP = {
  extracted_songs: setProgress,
  total_songs: setTotal,
  atlas_loaded: setAtlas,
  pca_df_loaded: setPreprocess,
  graph_loaded: setGraph,
  grid_loaded: setGrid,
  cluster_loaded: setCluster,
  library_loaded: setLibraryCrawled,
  errored: setErrored
};

/**
 * Periodically executes the check_progress API call until stopped or completed.
 *
 * @param {*} param0
 */
function* checkProgressSaga({ getState }) {
  // check if backend data matches expected version
  while (true) {
    const {
      explore: { graphLoaded, gridLoaded, clusterLoaded, atlasLoaded },
      library: { libraryLoaded },
      user: { ID, token }
    } = getState();
    // if fully loaded, exit
    if (
      graphLoaded &&
      gridLoaded &&
      clusterLoaded &&
      libraryLoaded &&
      atlasLoaded
    ) {
      break;
    }
    // if unauthorized, exit
    if (!ID || !token) {
      yield put({ type: 'CLEAR_ALL' });
      break;
    }
    const response = yield call(checkProgress, { ID, token });
    if (!response || response.unauthorized) {
      yield put({ type: 'CLEAR_ALL' });
      break;
    }
    // propagate progress results to each state value
    for (const field of Object.keys(FUNCTION_MAP)) {
      yield put(FUNCTION_MAP[field](response[field]));
    }
    // if library is finished crawling
    if (response.library_loaded) {
      // if songs exist, actually load the library
      if (response.total_songs !== 0) {
        yield put(handleLibrary({ data: true }));
        // otherwise, don't try. no point
      } else {
        break;
      }
    }
    // if fully loaded, finish
    if (response.fully_loaded) {
      break;
    }
    // pause for a delay
    yield delay(DELAY_TIME_MS);
  }
  yield put(stopCheckProgress());
}

/**
 * Watches for signals to run the check progress function. If stop check
 * progress is called, then the check_progress call is cancelled.
 * @param  {...any} args
 * @param getState
 */
export function* watchCheckProgressSaga({ getState }) {
  yield takeLeading([startCheckProgress.type], function* () {
    const {
      user: { ID, token }
    } = getState();
    // check progress first
    const response = yield call(checkProgress, { ID, token });
    if (!response || response.unauthorized) {
      yield put({ type: 'CLEAR_ALL' });
    }
    if (!response.fully_loaded) {
      // begin processing (this does nothing if processing already happened or already started)
      yield call(startProcess, { ID, token });
    }
    const { result } = yield call(checkBackendVersion, { ID, token });
    if (!result) {
      yield put(clearUserBackend({ withTracks: false }));
      return;
    }
    // start check progress task in background
    const bgCheckProgressTask = yield fork(checkProgressSaga, { getState });
    // wait for user to stop the task or for it to complete
    yield take(stopCheckProgress.type);
    // on stop, cancel the background task
    yield cancel(bgCheckProgressTask);
  });
}
