export const staticMode = process.env.REACT_APP_MODE === 'STATIC';

export const LoadState = {
  LoadingPersistor: 0,
  LibraryCrawling: 1,
  LibraryLocalLoading: 2,
  ExtractingTracks: 3,
  Preprocessing: 4,
  LoadGraph: 5,
  LoadGrid: 6,
  LoadClusters: 7,
  LoadAtlas: 8,
  Done: 9
};

export const LoadMessages = {
  [LoadState.LoadingPersistor]: ['Loading...', 'Loaded local state.'],
  [LoadState.LibraryCrawling]: ['Crawling library...', 'Crawled library.'],
  [LoadState.LibraryLocalLoading]: [
    'Loading library into storage...',
    'Loaded library into storage.'
  ],
  [LoadState.ExtractingTracks]: ['Extracting tracks...', 'Extracted tracks.'],
  [LoadState.Preprocessing]: [
    'Preprocessing features...',
    'Preprocessed features.'
  ],
  [LoadState.LoadGraph]: ['Plotting your tracks...', 'Plotted tracks.'],
  [LoadState.LoadGrid]: ['Optimizing track space...', 'Optimized track space.'],
  [LoadState.LoadClusters]: ['Discovering clusters...', 'Discovered clusters.'],
  [LoadState.LoadAtlas]: ['Loading track atlas...', 'Loaded track atlas.'],
  [LoadState.Done]: ['Done.']
};

export const ErrorState = {
  NoError: 0,
  LibraryError: 1,
  AtlasError: 2,
  ExtractionError: 3,
  PreprocessingError: 4,
  GraphError: 5,
  GridError: 6,
  ClusterError: 7
};

export const ErrorMessages = {
  [ErrorState.NoError]: ['No error'],
  [ErrorState.LibraryError]: ['Library error'],
  [ErrorState.AtlasError]: ['Atlas error'],
  [ErrorState.ExtractionError]: ['Extraction error'],
  [ErrorState.PreprocessingError]: ['Preprocessing error'],
  [ErrorState.GraphError]: ['Graph error'],
  [ErrorState.GridError]: ['Grid error'],
  [ErrorState.ClusterError]: ['Cluster error']
};
