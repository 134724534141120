import { useEffect, useMemo, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {
  useSelectLibraryLoaded,
  useSelectTrackInfo
} from 'slices/librarySlice';
import { getMediumImage } from 'utils/libraryUtils';

export default function ImageCycler() {
  const libraryLoaded = useSelectLibraryLoaded();
  const trackInfo = useSelectTrackInfo();
  const [currentCover, setCurrentCover] = useState(0);
  const albumCovers = useMemo(
    () =>
      libraryLoaded
        ? [
            ...new Set(
              Object.values(trackInfo).map((track) => getMediumImage(track))
            )
          ]
        : [],
    [libraryLoaded, trackInfo]
  );

  useEffect(() => {
    if (!albumCovers.length) {
      return;
    }
    const timeout = setTimeout(
      () => setCurrentCover((x) => (x + 1) % albumCovers.length),
      3000
    );
    return () => clearTimeout(timeout);
  }, [currentCover, albumCovers]);

  return (
    <TransitionGroup>
      <CSSTransition
        key={currentCover}
        classNames="Transition__fade"
        appear={true}
        timeout={200}
      >
        <img alt="alt" src={albumCovers[currentCover]} />
      </CSSTransition>
    </TransitionGroup>
  );
}
