/*
 * SpotifyFlow.js
 * author: evan kirkiles
 * created on Wed Feb 01 2023
 * 2023 channel studio
 */
import { loginURL } from 'api/client';
import classNames from 'classnames';
import { useState } from 'react';
import { TfiClose } from 'react-icons/tfi';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { clearPlatform, useSelectLoggedIn } from 'slices/userSlice';

export default function SpotifyLoginFlow({ platform }) {
  const active = platform === 'spotify';
  const hidden = !active && !!platform;

  // statefuls
  const loggedIn = useSelectLoggedIn();
  const dispatch = useDispatch();

  // parse parameters from querystring for token
  const location = useLocation();
  const origin = encodeURI(window.location.origin);
  const [isRedirecting, setIsRedirecting] = useState(platform === 'spotify');

  return (
    <div
      className={classNames('Login__login-flow', {
        'Login__login-flow--hidden': hidden
      })}
    >
      <a
        className={classNames('Button', `Login__login-button`, {
          'pointer-events-none': loggedIn,
          'Login__login-button--active': active
        })}
        href={`${loginURL}&state=${origin}${location.pathname}`}
        onClick={(e) => {
          setIsRedirecting(true);
          if (platform === 'spotify') {
            e.preventDefault();
            dispatch(clearPlatform());
            setIsRedirecting(false);
          }
        }}
      >
        {platform === 'spotify' ? (
          <>
            Logging in with Spotify
            <span>
              <TfiClose />
            </span>
          </>
        ) : (
          <>
            Log in with Spotify<span>→</span>
          </>
        )}
      </a>
      {isRedirecting && (
        <div className="Login__statemessage medium-text">
          <span>Redirecting...</span>
        </div>
      )}
    </div>
  );
}
