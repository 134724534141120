/*
 * Logout.js
 * author: evan kirkiles
 * created on Mon Feb 20 2023
 * 2023 channel studio
 */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearMusicKit } from 'slices/userSlice';

export default function LogoutPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(clearMusicKit());
    dispatch({ type: 'CLEAR_ALL' });
    navigate('/login', { replace: true });
  }, [dispatch, navigate]);
  return null;
}
