/*
 * ProfilePane.js
 * author: evan kirkiles
 * created on Thu Feb 02 2023
 * 2023 channel studio
 */
import classNames from 'classnames';
import { VscArrowLeft } from 'react-icons/vsc';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSelectExploreTotal } from 'slices/exploreSlice';
import {
  clearUserBackend,
  useSelectDisplayName,
  useSelectPlatform
} from 'slices/userSlice';

export default function ProfilePane({ active, deactivate, closeMenu }) {
  const total = useSelectExploreTotal();
  const displayName = useSelectDisplayName();
  const platform = useSelectPlatform();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // clears a user's data from our database
  const onReprocessLibrary = async () => {
    await dispatch(clearUserBackend({ withTracks: false }));
    closeMenu();
    navigate('/');
  };

  return (
    <ul
      className={classNames('Menu__subview', 'Menu__profile', 'Menu__items', {
        'Menu__subview-active': active
      })}
    >
      <li className="Menu__subview_backbutton Menu__profile_action">
        <button onClick={deactivate}>
          <VscArrowLeft />
        </button>
      </li>
      <li className="Menu__profile_header">
        <button onClick={deactivate}>Profile</button>
      </li>
      <li className="Menu__profile_info">
        {`${platform} Account: `}
        <span>{displayName ?? '???'}</span>
      </li>
      <li className="Menu__profile_info">
        {'Songs: '}
        <span>
          {total && total >= 0 ? total.toLocaleString('en-US') : '???'}
        </span>
      </li>
      <li className="Menu__profile_action">
        <button onClick={onReprocessLibrary}>Reprocess Library</button>
      </li>
    </ul>
  );
}
