import classnames from 'classnames';
import Menu from 'components/Menu';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import {
  useSelectBookmarks,
  useSelectLibraryLoaded
} from 'slices/librarySlice';
import { setToured, useSelectToured } from 'slices/tourSlice';
import IconLogo from 'svg/logo2';

export default function Header({ unloaded, className }) {
  const bookmarks = useSelectBookmarks();
  const libraryLoaded = useSelectLibraryLoaded();
  const dispatch = useDispatch();
  const libraryTipToured = useSelectToured('libraryTip');
  const menuTipToured = useSelectToured('menuTip');

  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  return (
    <header className={classnames('Header', className)}>
      <a
        href="mailto:seth@kranzler.net"
        target="_blank"
        rel="noopener noreferrer"
        className="Header__logo ClickItem"
      >
        {/* <Link
          to="/"
          className="Header__logo"
          onClick={() => setMenuOpen(false)}
        > */}
        <IconLogo className="Header__logo-icon" />
        {/* <IconLogoCS className="Header__logo-icon2" /> */}
        {/* </Link> */}
        <div className="Header__cs">by Seth Kranzler</div>
      </a>
      {libraryLoaded && (
        <nav className="Header__inner Header__inner--left flex items-stretch">
          <ul className="Header__links">
            {/* <TourWrapper
              steps={libraryTip}
              tourName="libraryTip"
              showDots={false}
              showCloseButton={false}
            > */}
            <li className="Header__link">
              <Link
                id="Header_Library"
                to="/library"
                className={classnames('ClickItem', {
                  'ClickItem-active': location.pathname === '/library'
                })}
                onClick={() => {
                  if (!libraryTipToured) {
                    dispatch(setToured({ item: 'libraryTip', toured: true }));
                  }
                }}
              >
                Library
              </Link>
            </li>
            {/* </TourWrapper> */}
            <li className="Header__link">
              <Link
                className={classnames('ClickItem', {
                  'ClickItem-active': location.pathname === '/bookmarks'
                })}
                to="/bookmarks"
              >
                Bookmarks&nbsp;({bookmarks?.length ?? 0})
              </Link>
            </li>
          </ul>
        </nav>
      )}
      {/* <TourWrapper
        steps={menuTip}
        tourName="menuTip"
        showDots={false}
        showCloseButton={false}
      > */}
      <button
        className={classnames('Header__hamburger', {
          'Header__hamburger-expanded': menuOpen
        })}
        onClick={() => {
          setMenuOpen(!menuOpen);
          if (!menuTipToured) {
            dispatch(setToured({ item: 'menuTip', toured: true }));
          }
        }}
      ></button>
      {/* </TourWrapper> */}
      <Menu expanded={menuOpen} closeMenu={() => setMenuOpen(false)} />
    </header>
  );
}
