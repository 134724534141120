/*
 * LoadingLibrary.js
 * author: evan kirkiles
 * created on Mon Feb 06 2023
 * 2023 channel studioi
 */

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useSelectExploreTotal } from 'slices/exploreSlice';
import { LoadMessages, LoadState } from 'utils/constants';

export default function LoadingLibrary({ loadStatus }) {
  const totalSongs = useSelectExploreTotal();
  return (
    <article className="Loading">
      <section className="Loading__inner">
        <div className="Loading__pane Loading__pane-bordered">
          <SwitchTransition>
            <CSSTransition
              key={loadStatus}
              appear
              timeout={300}
              classNames={'Transition__fadeupswitch'}
            >
              <div className="Loading__crawlingmessage">
                {loadStatus !== LoadState.Done && LoadMessages[loadStatus][0]}
                {loadStatus === LoadState.LibraryCrawling && (
                  <>
                    <br />
                    <span className="Loading__crawlingmessage-progress">
                      {totalSongs} tracks found so far.
                    </span>
                  </>
                )}
              </div>
            </CSSTransition>
          </SwitchTransition>
          {(loadStatus === LoadState.LibraryCrawling ||
            loadStatus === LoadState.LibraryLocalLoading) && (
            <div className="Loading__disclaimer">
              <span>
                This may take several minutes, and even longer for larger
                libraries.
              </span>
              <span>
                Closing this tab will not interrupt processing, so feel free to
                come back later.
              </span>
            </div>
          )}
        </div>
      </section>
    </article>
  );
}
