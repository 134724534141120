import React from 'react';
import Header from 'components/Header';
import AboutPane from 'components/Menu/AboutPane';

export default function Privacy() {
  return (
    <>
      <Header className="AboutPage__header" />
      <AboutPane pageMode={true} />
    </>
  );
}
