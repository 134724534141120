import React, { useEffect } from 'react';

import DevTools from 'components/DevTools';
import { Route, Routes, useLocation } from 'react-router';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { useLazyLoad } from 'utils/lazyUtils.js';

//components
import Header from 'components/Header';
import Nav from 'components/Nav';

//requires
import RequireAuth from 'components/Require/RequireAuth';
import RequireExplore from 'components/Require/RequireExplore';
import RequireLibrary from 'components/Require/RequireLibrary';

// pages
import LoginPage from 'pages/Login';
import LogoutPage from 'pages/Logout';
import Terms from 'pages/Terms';
import Privacy from 'pages/Privacy';
import About from 'pages/About';

import LoadingMessages from 'views/Loading/LoadingMessages';

import 'styles/App.scss';

const PreviewPlayerLoader = () => import('components/PreviewPlayer');
const BookmarksViewLoader = () => import('views/Bookmarks');
const ClusterViewLoader = () => import('views/Cluster');
const LibraryViewLoader = () => import('views/Library');
const PlaylistViewLoader = () => import('views/Playlist');
const UmapViewLoader = () => import('views/Umap');

export default function App() {
  const location = useLocation();

  const [
    isLoading,
    load,
    [
      PreviewPlayer,
      BookmarksView,
      ClusterView,
      LibraryView,
      PlaylistView,
      UmapView
    ]
  ] = useLazyLoad([
    PreviewPlayerLoader,
    BookmarksViewLoader,
    ClusterViewLoader,
    LibraryViewLoader,
    PlaylistViewLoader,
    UmapViewLoader
  ]);

  async function startLoad() {
    await load();
  }

  useEffect(() => {
    startLoad();
  }, []);

  return (
    <>
      <SwitchTransition>
        <CSSTransition
          key={location.pathname === '/login' ? 'login' : 'other'}
          classNames="Transition__fade"
          appear={location.pathname === '/login'}
          timeout={300}
        >
          <Routes location={location}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route
              path="*"
              element={
                <RequireAuth>
                  <div className="Interface">
                    <Header />
                    <RequireLibrary>
                      <Nav />
                      <RequireExplore noLoader>
                        {UmapView && (
                          <UmapView hidden={location.pathname !== '/'} />
                        )}
                      </RequireExplore>
                      <Routes location={location}>
                        <Route
                          path={`library`}
                          element={LibraryView && <LibraryView />}
                        />
                        <Route
                          path={`bookmarks`}
                          element={BookmarksView && <BookmarksView />}
                        />
                        <Route
                          path={'*'}
                          element={
                            <RequireExplore>
                              <Routes>
                                <Route
                                  path={`playlists`}
                                  element={PlaylistView && <PlaylistView />}
                                />
                                <Route
                                  path={`clusters`}
                                  element={ClusterView && <ClusterView />}
                                />
                              </Routes>
                            </RequireExplore>
                          }
                        />
                      </Routes>
                      {PreviewPlayer && <PreviewPlayer />}
                    </RequireLibrary>
                    <LoadingMessages />
                  </div>
                </RequireAuth>
              }
            />
            <Route path="/terms-of-use" element={<Terms />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </CSSTransition>
      </SwitchTransition>
      <DevTools />
    </>
  );
}
