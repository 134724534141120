export const DEBOUNCE_TIME = 300;

export const searchOptions = {
  threshold: 0.3,
  ignoreLocation: true,
  keys: ['name', 'artist_name', 'album_name']
};

const identityMap = (items) => items;

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export const doSearch = ({
  searchTerm,
  setter = identityMap,
  mapper,
  fuseObject
}) => {
  return setter(
    mapper({
      items:
        searchTerm.trim().length > 0
          ? fuseObject.search(escapeRegExp(searchTerm)).map((x) => x.item)
          : fuseObject._docs,
      searchTerms: searchTerm?.toLowerCase().split(' ')
    })
  );
};

const getMatchIndices = ({ str, searchTerms }) => {
  const indices = [];
  if (!searchTerms?.length) {
    return [];
  }
  searchTerms.forEach((searchTerm) => {
    if (!searchTerm?.length) {
      return;
    }
    const regex = new RegExp(escapeRegExp(searchTerm), 'gi');
    var result;
    while ((result = regex.exec(str))) {
      indices.push([result.index, result.index + searchTerm.length - 1]);
    }
  });
  return indices;
};

export const applyHighlight = ({ str, searchTerms }) => {
  if (!searchTerms?.length) {
    return [[false, str]];
  }
  const indices = getMatchIndices({ str: str, searchTerms: searchTerms });
  const matches = str
    .split('')
    .map((_, i) => indices.some(([a, b]) => a <= i && i <= b));
  if (!matches.some(Boolean)) {
    return [[false, str]];
  }
  let i = 0;
  return matches
    .reduce((acc, value) => {
      const { length: l } = acc;
      if (acc[l - 1]?.value === value) {
        acc[l - 1].count++;
        return acc;
      }
      return acc.concat({ value, count: 1 });
    }, [])
    .map(({ value, count }) => {
      const slice = str.slice(i, i + count);
      i += count;
      return [value, slice];
    });
};
